import { useEffect, useState, Fragment, useContext } from "react";
import { FeedRequestCardMobile, ProfileRequestCardMobile } from "./RequestCards";
import { 
    CardRecommendationRequests,
    ChatsRequest, 
    ContactsRequest, 
    EmailAuth, 
    EmailPrefsUpdate, 
    FeedRequest, 
    FirstLoginAdd, 
    GoogleAuth, 
    InviteTokenBurn, 
    InviteTokenUse, 
    LinkedInAuth, 
    MatchRequest, 
    NewOffer,  
    OffersRequest, 
    OpenStripePortal, 
    ProfileRequest, 
    ReactOffer, 
    RelevanceRequest, 
    SearchRequest, 
    SendInvite, 
    SetReadyStatus, 
    Subscribe, 
    SubscriptionsRequest,
    ToRateRequest,
    ToRateSkip
} from "../Requests";
import { useCookies } from "react-cookie";
import React from "react";
import {    
    NewAvatar,
    Edit, 
    Loading,
    SmallUserBlock,
    CardMatchesVideoMobile,
} from "../elements/Elements.jsx";
import {   
    PopupHintMobile,
    PopupBottomSettings,
    PopupLogOutMobile,
    PopupUnsubscribeMobile,
    PopupCenter,
    PopupInviteMobile,
    InfoPopup,
    InfoErrorPopup,
    GradePopup,
} from "../elements/Popups";
import { useNavigate } from "react-router";
import { 
    DateFromString,
    DateTimeFromString, 
    DateToTimestamp, 
    EmailRegExp, 
    GetWebsocketUrl, 
    MakeValidURL, 
    OnlyValid, 
    ScreenColors,  
    SetDict, 
    TimeFromString, 
    URLRegExp,
    ClipBoard,
    Share,
    Industries,
    CreateHSLColor,
    UntilMatch,
    RequestColors2,
    Until,
    CommunityLogo,
} from "../elements/Data";
import { 
    AgreementBlack,
    AngleRightBlack,
    AppleWhite,
    CardBlack,
    CopyBlack,
    ExitBlack,
    FacebookWhite,
    FunnelBlack,
    GearBlack,
    GoogleLogo,
    LinkBlack,
    LinkedInLogo,
    LinkedInWhite,
    LocationGray,
    OkWhite,
    PhoneWhite,
    PlusBlack,
    PlusGreen,
    PlusWhite,
    ProtectBlack,
    SendMsgBlack,
    SettingsBlack,
    StarYellow,
    SupportBlack,
    TelegramWhite,
    TrashBlack,
    XWhite, 
} from "../elements/Imgs";
import { ChatListMobile, OfferListMobile } from "./ChatMobile";
import { MessageCardMobile } from "./RequestCards";
import { useSearchParams } from "react-router-dom";
import { GlobalContext, ProfileContext } from "../elements/Contexts";
import { SiteCard } from "../elements/Elems";
import { FormattedMessage } from "react-intl";
import { messages } from "../../i18n/messages";

export const ProfileFeedMobile = (props: any) => {
    const [ cookie ] = useCookies([`user_id_cookie`]);
    const context = useContext(ProfileContext);
    const globalContext = useContext(GlobalContext);
    const [ searchParams ] = useSearchParams();
    
    const [ feed, setFeed ] = useState<any[] | null>(null);
    const [ recRequests, setRecRequests ] = useState<any | null>(null);
    // const [ tab, setTab ] = useState<number>(searchParams.get(`first_login`) === `true` ? 1 : 0);
    const [ backFeed, setBackFeed ] = useState<any[] | null>(null);
    // const [ sortOld, setSortOld ] = useState<boolean>(false); 
    const [ rndColor, setRndColor ] = useState<string[]>([]);
    const [ openFilter, setOpenFilter ] = useState<boolean>(false);
    const [ filterByIndustry, setFilterByIndustry ] = useState<string[]>([]);
    const [ filterIndustrySearch, setFilterIndustrySearch ] = useState<string>('');
    const [ filterByRequest, setFilterByRequest ] = useState<number[]>([]);

    useEffect(() => {
        if (!!filterByIndustry?.length || !!filterByRequest?.length) {
            setOpenFilter(true);
        }
    }, [filterByRequest?.length, !filterByIndustry?.length])

    const FilterSearchRequests = (requests: any[]) => {
        return requests.filter((val: any) => (
            (filterByIndustry.filter(val_ => (val?.user?.industry ?? []).includes(val_))?.length > 0 || filterByIndustry?.length === 0) &&
            (filterByRequest.includes(val?.card?.type) || filterByRequest?.length === 0)
        ));
    }

    const FilterFeed = (users: any[]) => {
        return users.filter(
            (val: any) => (filterByIndustry.filter(val_ => (val?.industry ?? []).includes(val_))?.length > 0 || filterByIndustry?.length === 0)
        ).map((val: any) => {return {
            ...val,
            requests: (val?.requests ?? []).filter((request: any) => filterByRequest.includes(request?.type) || filterByRequest?.length === 0)
        }});
    }

    const [ feedPage, setFeedPage ] = useState<number>(0);
    const [ moreFeed, setMoreFeed ] = useState<boolean>(true);  
    // const [ morePeople, setMorePeople ] = useState<boolean>(true); 

    // const [ showSearch, setShowSearch ] = useState<boolean>(false);
    const [ timer, setTimer ] = useState<any>();
    const [ searchData, setSearchData ] = useState<any>();
    // const [ lastSearch, setLastSearch ] = useState<string>("");
    const [ searchAbortController, setSearchAbortController ] = useState<AbortController>(new AbortController());

    const [ hintPopup, setHintPopup ] = useState<{
        show: boolean,
        title?: string | React.JSX.Element,
        description?: any,
        buttonTitle?: string,
        onClick?: Function,
        close?: boolean,
        description_type?: boolean,
    }>();

    const topScroller = () => {
        if (window.scrollY > document.documentElement.scrollHeight - document.documentElement.clientHeight - 500) {
            setMoreFeed(true);
            // setMorePeople(true);
        }
    }

    useEffect(() => {
        if (feed?.length === 0) {
            context.setTab(1);
        }
    }, [feed])   

    useEffect(() => {
        setMoreFeed(false);
    }, [feed?.length ?? 0])

    useEffect(() => {
        if (moreFeed) {
            FeedRequest(cookie['user_id_cookie'], setFeed, setBackFeed, feedPage, () => {
                setFeedPage(val => Math.min(10000, val + 1)); 
            });
        }
    }, [moreFeed])

    useEffect(() => {
        if (context.profile?.about) {
            CardRecommendationRequests(
                cookie[`user_id_cookie`], 
                context.profile?.about,
                setRecRequests,
            )
        } else if (context.profile?.industry?.length > 0) {
            CardRecommendationRequests(
                cookie[`user_id_cookie`], 
                context.profile?.industry.join(","),
                setRecRequests,
            )
        }
    }, [context.profile?.about, context.profile?.industry])

    useEffect(() => {        
        document.addEventListener('scroll', topScroller);          

        context.setGlobalSearch(searchParams.get("search") ?? "");

        const savedRecRequests = sessionStorage.getItem("recRequests");
        if (savedRecRequests) {
            setRecRequests(JSON.parse(savedRecRequests));
        }

        return () => {
            document.removeEventListener('scroll', topScroller);
            context.setGlobalSearch("");
        }
    }, [])  
    
    useEffect(() => {
        // const hist = JSON.parse(localStorage.getItem(`search_history`) ?? '[]');
        // setLastSearch(hist[0] ?? "");
    }, [searchData])
    
    useEffect(() => {
        clearTimeout(timer);
        searchAbortController.abort();
        const newAbortController = new AbortController();
        setSearchAbortController(newAbortController);
        if (!!context.globalSearch?.length || !!filterByIndustry?.length || !!filterByRequest?.length) {
            setTimer(setTimeout(() => {
                SearchRequest(
                    context.globalSearch ?? "", 30, setSearchData, 
                    filterByIndustry?.length > 0 ? filterByIndustry : null,
                    filterByRequest?.length > 0 ? filterByRequest : null,
                    newAbortController.signal,
                );
            }, 500))
        } else {
            setSearchData(undefined);
        }
    }, [context.globalSearch, filterByIndustry, filterByRequest])   
    
    useEffect(() => {       
        if (recRequests && !(props.profile?.first_login ?? [`feed`]).includes(`feed`)) {
            document.documentElement.style.overflowY = 'hidden';
            context.setTab(1);
            setHintPopup({
                show: true,
                title: <FormattedMessage id="suggested_requests"/>,
                description: <>
                    <p data-size={6}>
                        {`These are the requests we've curated for you based on your professional background and bio. Discover who could benefit greatly from your expertise.`}
                    </p>
                    <div className={`block p-2 b-2 w-flex mb mt-2`} data-color={`light-green`}>
                        <p data-size={8} data-color={`dark-green`} className={`semibold seminarrow mb`}>
                            <FormattedMessage id="i_can_help"/>
                        </p>
                        <p data-size={7} data-color={`dark-green`}>
                            {`Notify the person of your ability to aid them and share further details through a private message.`}
                        </p>
                    </div>
                    <div className={`block p-2 b-2 w-flex mb`} data-color={`light-green`}>
                        <p data-size={8} data-color={`dark-green`} className={`semibold seminarrow mb`}>
                            <FormattedMessage id="suggest_friend"/>
                        </p>
                        <p data-size={7} data-color={`dark-green`}>
                            {`Do you have connections who could assist? Share the request and simplify the intro process.`}
                        </p>
                    </div>
                </>,
                description_type: true,
                buttonTitle: `Ok, i got it`,
                onClick: () => { 
                    FirstLoginAdd(
                        cookie[`user_id_cookie`],
                        `feed`, () => {
                            props.setProfile((val: any) => SetDict(val, [`first_login`], val.first_login.concat([`feed`])));
                        }
                    )
                },
                close: true,
            });
        }        

        if (recRequests) {
            sessionStorage.setItem("recRequests", JSON.stringify(recRequests));
        }
    }, [props.profile, recRequests])

    useEffect(() => {        
        setRndColor(ScreenColors[Math.floor(Math.random() * 3)]);
    }, [props.recommendationsCommon])

    return (
        <div className={`w-flex px-3 pt-2`}>
            <div className={`row left gap-0 nowrap x-auto mb-2 w-flex-p-3 neg-mx-3 px-3`} hidden={true}>
                <button className={`block btn-tab-new mobile row`} data-color={context.tab === 1 ? `black` : `white`}
                        onClick={() => { context.setTab(1) }}>
                    <p><FormattedMessage id="explore"/></p>
                </button>
                <button className={`block btn-tab-new mobile row`} data-color={context.tab === 0 ? `black` : `white`}
                        onClick={() => { context.setTab(0) }} disabled={!feed?.length}>
                    <p>{`My connections`}</p>
                </button>
            </div>

            {!!props.profile?.about && props.profile?.requests?.length > 0 && props.profile?.projects?.length === 0 && false &&
            <div className={`label block btn-block-new mobile w-flex mb`} 
                    data-color={`extra-light-coral`} data-border={`coral-3`}>
                <p><FormattedMessage id="add_one_project"/></p>
                <p data-size={6} className={`regular mt`}>
                    <FormattedMessage id="profile_add_project_description"/>
                </p>
                <button className={`block btn-block-new mobile w-flex mt-2 row nowrap`} onClick={() => {
                        context.setAddProjectPopup(true);
                    }} style={{padding: 14, borderRadius: 14}}>
                    <p><FormattedMessage id="profile_add_a_project"/></p>
                    <div className={`btn-symbol h-3 w-3 centered`}>
                        <img src={PlusBlack} alt={`+`}></img>
                    </div>
                </button>
            </div>}

            {props.profile?.requests?.length > 0 && !props.profile?.about && false &&
            <div className={`label block btn-block-new mobile w-flex mb`} 
                    data-color={`extra-light-coral`} data-border={`coral-3`}>
                <p><FormattedMessage id="profile_tell_about"/></p>
                <p data-size={6} className={`regular mt`}>
                    {`Introduce yourself in a way that will pique the interest of the people you are looking for. `}
                </p>
                <button className={`block btn-block-new mobile w-flex mt-2 row nowrap`} onClick={() => {
                        context.setPage(0, 3);
                        context.setTab(3, 0);
                    }} style={{padding: 14, borderRadius: 14}}>
                    <p><FormattedMessage id="profile_add_about"/></p>
                    <div className={`btn-symbol h-3 w-3 centered`}>
                        <img src={PlusBlack} alt={`+`}></img>
                    </div>
                </button>
            </div>}

            {/* {props.profile?.requests?.length === 0 ?
            <div className={`label block btn-block-new mobile w-flex mb-1`} 
            data-color={`extra-light-coral`} data-border={`coral-3`}>
                <p><FormattedMessage id="post_one_request_hint"/></p>
                <button className={`block btn-block-new mobile w-flex mt-2 row nowrap`} onClick={() => {
                        context.setAddRequestPopup(true);
                    }} disabled={!props.canAddRequest} style={{padding: 14, borderRadius: 14}}>
                    <p><FormattedMessage id="post_a_new_request"/></p>
                    <div className={`btn-symbol h-3 w-3 centered`}>
                        <img src={PlusBlack} alt={`+`}></img>
                    </div>
                </button>
            </div> :
            <button className={`block btn-block-new mobile w-flex mb-1`} onClick={() => {
                    context.setAddRequestPopup(true);
                }} disabled={!props.canAddRequest} style={{padding: 14}}>
                <div className={`row nowrap`}>
                    <p><FormattedMessage id="post_a_request"/></p>
                    <div className={`btn-symbol h-3 w-3 centered`}>
                        <img src={PlusBlack} alt={`+`}></img>
                    </div>
                </div>
            </button>} */}

            <div className={`label block btn-block-new mobile mb-2 w-flex`} 
                onClick={() => { setOpenFilter(true) }} style={{padding: 14}}>
                <div className={`row nowrap pl`}>
                    <p><FormattedMessage id="feed_filter"/></p>
                    <img className={`not-scale w-3`} src={FunnelBlack} alt={`>`}></img>
                </div>
                {(openFilter) && <>
                <div className={`w-flex my-2 row left gap-0`}>
                    {[
                        <FormattedMessage id="request_type_0"/>,
                        <FormattedMessage id="request_type_1"/>,
                        <FormattedMessage id="request_type_2"/>,
                        <FormattedMessage id="request_type_3"/>,
                        <FormattedMessage id="request_type_4"/>,
                        <FormattedMessage id="request_type_5"/>,
                        <FormattedMessage id="request_type_6"/>,
                        <FormattedMessage id="request_type_7"/>,
                        <FormattedMessage id="request_type_8"/>,
                    ].map((elem, index) => {return (
                        <button key={index} className={`block btn-circled-32 mobile row left ${filterByRequest.includes(index) ? 'p-right' : ''}`} 
                                data-color={`light-gray`} 
                                type={`button`} onClick={() => { 
                                    setFilterByRequest(
                                        val => val.includes(index) ? val.filter(elem => elem !== index) : val.concat([index])) 
                                }}>
                            <p data-color={!filterByRequest.includes(index) ? `black-40-opacity` : null}>{elem}</p>
                            <div className={`btn-symbol centered w-3 h-3 close`} hidden={!filterByRequest.includes(index)}>
                                <img src={PlusBlack} alt={`+`}></img>
                            </div>
                        </button>
                    )})}
                </div>
                <p className={`pl`}><FormattedMessage id="feed_filter_by_industry"/></p>
                <input className={`mobile w-flex my-2`} placeholder={messages[globalContext.locale].industry_ph_40} 
                                data-size={6} value={filterIndustrySearch}
                                onChange={e => { setFilterIndustrySearch(e.target.value) }}></input>
                            <div className={`row left mb-2`} data-color={`light-gray`}>
                            {((
                                    Industries.filter((val: string) => val.toLowerCase().includes(filterIndustrySearch.toLocaleLowerCase().trim()))?.length &&
                        filterIndustrySearch.trim()?.length
                    ) ?
                    Industries.filter((val: string) => val.toLowerCase().includes(filterIndustrySearch.toLocaleLowerCase().trim())) : (filterByIndustry?.length ? filterByIndustry : context.profile.industry))
                    .map((elem: string, index: number) => {return (
                        <button className={`block btn-circled-32 mobile row`} 
                                data-color={filterByIndustry.includes(elem) ? `transparent` : `light-gray`}
                                data-border={filterByIndustry.includes(elem) ? `green-1` : null}
                                onClick={() => {
                                    setFilterByIndustry((val: any) => 
                                        val.includes(elem) ? val.filter((val_: string) => val_ !== elem) : val.concat([elem])
                                    )
                                    const input = document.querySelector(`#input-tag`) as HTMLInputElement;
                                    if (input) {
                                        input.focus();
                                    }
                                }} type={`button`} id={`select-btn`} key={index}>
                            <p data-color={filterByIndustry.includes(elem) ? 'green' : `black-40-opacity`}>
                                {elem}
                            </p>
                            {filterByIndustry.includes(elem) &&
                            <img src={PlusGreen} alt={`+`} style={{transform: `rotate(45deg)`}} className={` neg-mr w-2`}></img>}
                        </button>
                    )})}
                </div>

                <button className={`block btn-circled-32 desktop row center mt-3 w-6`}
                        onClick={e => { e.stopPropagation(); setOpenFilter(false) }} data-color={`black`}>
                    <p><FormattedMessage id="ok"/></p>
                </button>
                </>}
            </div>

            {context.tab === 0 && 
            (!!feed && !!backFeed ? <>
                {!feed?.length ? 
                <MessageCardMobile profile={props.profile} type={`feed`}/> : <>
                    {/* <div className={`block label btn-block mobile w-flex mb-2`}>
                        <p className={`mb-2`}><FormattedMessage id="feed_sort_feed"/></p>
                        <button className={`row left noscroll nowrap mb-2 btn-double`}
                                onClick={() => {
                                    setSortOld(false);
                                }}>
                            <div className={`checkbox-radio w-3 h-3 b-2 centered mb noshrink`} data-checked={!(sortOld)}>
                                <div className={`checkbox-center h-1 w-1 b`}></div>
                            </div>
                            <p data-size={6}><FormattedMessage id="feed_new_first"/></p> 
                        </button>
                        <button className={`row left noscroll nowrap btn-double`}
                                onClick={() => {
                                    setSortOld(true);
                                }}>
                            <div className={`checkbox-radio w-3 h-3 b-2 centered mb noshrink`} data-checked={sortOld}>
                                <div className={`checkbox-center h-1 w-1 b`}></div>
                            </div>
                            <p data-size={6}><FormattedMessage id="feed_old_first"/></p> 
                        </button>
                    </div> */}
                    
                    {/* <div className={`row left nogap mb-2 px-1`} onClick={() => { setSortOld(val => !val) }}>
                        <p className={`semibold seminarrow`} style={{fontSize: 16}}>
                            <span data-color={`black-40-opacity`} className={`pr-1`}>{`Sort`}</span>
                            {sortOld ? <FormattedMessage id="feed_old_first"/> : <FormattedMessage id="feed_new_first"/>}
                        </p>
                        <img src={AngleRightBlack} alt={`>`} style={{
                            transition: `transform 0.5s ease-in-out`,
                            transform: `rotate(${sortOld ? -90 : 90}deg)`
                        }} className={`w-3 neg-my`}></img>
                    </div> */}
                    
                    {!!feed && !!backFeed && FilterFeed(feed).slice(0, 3).map((elem, index) => { return (
                    <div className={`w-flex`} onClick={() => { 
                            // context.setProfilePopup({
                            //     ...elem, followed: true,
                            //     onlyRequest: (elem.requests ?? [])[0],
                            // })
                            if (window.getSelection()?.type !== "Range") {
                                const newWindow: WindowProxy | null = window.open(`/request/${(elem.requests ?? [])[0]?.id}`, '_blank');
                                if (newWindow) {
                                    newWindow.focus();
                                }
                            }
                        }} key={index}>
                        <FeedRequestCardMobile className={`w-flex mb-1`} profile={elem} onRequested={(id: string) => {
                                                    setFeed(val => val ? SetDict(val, [index, 'is_requested'], (val[index]?.is_requested ?? []).concat([id])) : val);
                                                }}
                                                mobile={true} onlyValid={true} index={index} setFilterByIndustry={setFilterByIndustry}/>
                    </div>)})}
                
                    <div className={`block label btn-block mobile w-flex mb-1`} data-color={rndColor[0]}>
                        <div className={`row left nowrap`}>
                            <div className={`block min-w-3 h-3 b-3 centered noshrink`} data-color={rndColor[2] ?? `black`}>
                                {!!props.recommendationsCommon?.length ? 
                                <p data-size={6} className={`narrow px-1`}>{props.recommendationsCommon?.length}</p> :
                                <Loading color={rndColor[2] === `white` ? `black` : `white`} width={14} height={14}/>}
                            </div>
                            <p data-color={rndColor[2]}><FormattedMessage id="feed_similar"/></p>
                        </div>
                        <div className={`row nogap nowrap min-w-flex neg-mx-3 mt-2`}>
                            <div className={`row x-auto left top noshrink nowrap w-flex px-2`}>
                                {props.recommendationsCommon?.length ?
                                props.recommendationsCommon.map((elem: any, index: number) => {return (
                                    <SmallUserBlock profile={elem?.user} index={index}
                                                    setRecommendations={props.setRecommendationsCommon} mobile={true}
                                                    onSubscribe={() => { 
                                                        FeedRequest(
                                                            cookie['user_id_cookie'], 
                                                            setFeed, setBackFeed, 0, 
                                                            () => { setFeedPage(1) },
                                                        )
                                                    }} onClick={() => { 
                                                        // context.setProfilePopup(elem.user);
                                                        const newWindow: WindowProxy | null = window.open(`/profile/${elem?.user?.id}`, '_blank');
                                                        if (newWindow) {
                                                            newWindow.focus();
                                                        } 
                                                    }} key={index}/>
                                )}) :
                                [1, 2, 3].map((elem, index) => {return (
                                    <div className={`block b-3 p-2 column top`} key={index}>
                                        <NewAvatar size={0} className={`mb-2 circled`}/>
                                        <div style={{height: 61}}>
                                            <div className={`skeleton b-4 h-3 mb-1`} style={{width: 180}}></div>
                                            <div className={`skeleton b-3 h-2 mb-2`} style={{width: 195}}></div>
                                        </div>
                                        <div className={`skeleton b-4`} style={{width: 198, height: 40}}></div>
                                    </div>
                                )})}
                            </div> 
                        </div> 
                    </div>

                    {FilterFeed(feed)?.length > 3 &&
                    (!!feed && !!backFeed && FilterFeed(feed).slice(3).map((elem, index) => { return (
                    <div className={`w-flex`} onClick={() => { 
                            // context.setProfilePopup({
                            //     ...elem, followed: true,
                            //     onlyRequest: (elem.requests ?? [])[0],
                            // })
                            if (window.getSelection()?.type !== "Range") {
                                const newWindow: WindowProxy | null = window.open(`/request/${(elem.requests ?? [])[0]?.id}`, '_blank');
                                if (newWindow) {
                                    newWindow.focus();
                                }
                            }
                        }} key={index}>
                        <FeedRequestCardMobile className={`mb-1 w-flex`} profile={elem} onRequested={(id: string) => {
                                                    setFeed(val => val ? SetDict(val, [index + 3, 'is_requested'], (val[index + 3]?.is_requested ?? []).concat([id])) : val);
                                                }}
                                                mobile={true} onlyValid={true} index={index} setFilterByIndustry={setFilterByIndustry}/>
                    </div>)}))}

                    <div className={`w-flex pb-3 pt-1 row center`} id={`feed-end-element`} style={{
                            transition: `opacity 0.2 ease-in-out`,
                            opacity: 0,
                        }}>
                        <p data-size={5} className={`semibold center`} data-color={`gray`}>
                            <FormattedMessage id="feed_end_1"/><br/>
                            <FormattedMessage id="feed_end_2"/>
                        </p>
                    </div>
                </>}
            </> : <>
                <div className={`skeleton w-flex b-3 h-8 mb-2`}></div>
                <div className={`skeleton w-flex b-3 h-10 mb-3`}></div>
            </>)}

            {context.tab === 1 && 
            (!!(searchData?.user_requests ?? recRequests) ? <>  
                {searchData?.users?.length === 0 && (
                    filterByIndustry?.length !== 0 ||
                    filterByRequest?.length !== 0
                ) &&
                <div className={`block w-flex b-3 h-7 centered mb-2`}>
                    <p data-size={6}><FormattedMessage id="nothing_found"/></p>
                </div>}
                
                {FilterSearchRequests(searchData?.user_requests ?? recRequests)?.length > 0 &&
                (FilterSearchRequests(searchData?.user_requests ?? recRequests).slice(0, 3).map((elem: any, index: number) => { return (
                <div className={`w-flex`} onClick={() => { 
                        // context.setProfilePopup({
                        //     ...elem.user,
                        //     onlyRequest: elem.card, 
                        // }) 
                        if (context.profile?.subscription?.is_subscription_active) {  
                            if (window.getSelection()?.type !== "Range") {
                                const newWindow: WindowProxy | null = window.open(`/request/${elem.card?.id}`, '_blank');
                                if (newWindow) {
                                    newWindow.focus();
                                }
                            }
                        } else {
                            context.setActivateSubscriptionPopup(true);
                        }
                    }} key={index}>
                    <FeedRequestCardMobile className={`mb-1 w-flex`} profile={elem.user} request={elem.card}  onRequested={(id: string) => {
                                                setRecRequests((val: any[]) => SetDict(val, [index, 'is_requested'], (val[index]?.is_requested ?? []).concat([id])));
                                            }}
                                            mobile={true} onlyValid={true} index={index} setFilterByIndustry={setFilterByIndustry} selectedTags={filterByIndustry}/>
                </div>)}))}

                <div className={`block label btn-block mobile w-flex mb-2`} data-color={rndColor[0]} style={{paddingBottom: 14}}>
                    {!searchData?.users?.length &&
                    <div className={`row left nowrap mb-2`}>
                        <div className={`block min-w-3 h-3 b-3 centered noshrink`} data-color={rndColor[2] ?? `black`}>
                            {!!props.recommendationsCommon?.length ? 
                            <p data-size={6} className={`narrow px-1`}>{props.recommendationsCommon?.length}</p> :
                            <Loading color={rndColor[2] === `white` ? `black` : `white`} width={14} height={14}/>}
                        </div>
                        <p data-color={rndColor[2]}>
                            <FormattedMessage id="feed_similar"/>
                        </p>
                    </div>}
                    <div className={`row nogap nowrap min-w-flex neg-mx-3`}>
                        <div className={`row x-auto left top noshrink nowrap w-flex px-2`}>
                            {(searchData?.users?.length > 0 ? searchData?.users : props.recommendationsCommon)?.length > 0 ?
                            (searchData?.users?.length > 0 ? searchData?.users : props.recommendationsCommon).map((elem: any, index: number) => {return (
                                <SmallUserBlock profile={elem?.user ?? elem} index={index} 
                                                setRecommendations={searchData?.users?.length > 0 ? () => {} : props.setRecommendationsCommon} mobile={true}
                                                setSearchData={searchData?.users?.length > 0 ? setSearchData : () => {}}
                                                onSubscribe={() => { 
                                                    FeedRequest(
                                                        cookie['user_id_cookie'], 
                                                        setFeed, setBackFeed, 0, 
                                                        () => { setFeedPage(1) },
                                                    )
                                                }} onClick={() => { 
                                                    // context.setProfilePopup(elem.user);
                                                    if (context.profile?.subscription?.is_subscription_active) {  
                                                        const newWindow: WindowProxy | null = window.open(`/profile/${(elem?.user ?? elem)?.id}`, '_blank');
                                                        if (newWindow) {
                                                            newWindow.focus();
                                                        } 
                                                    } else {
                                                        context.setActivateSubscriptionPopup(true);
                                                    }
                                                }} key={index}/>
                            )}) :
                            [1, 2, 3].map((elem, index) => {return (
                                <div className={`block b-3 p-2 column top`} key={index}>
                                    <NewAvatar size={0} className={`mb-2 circled`}/>
                                    <div style={{height: 61}}>
                                        <div className={`skeleton b-4 h-3 mb-1`} style={{width: 180}}></div>
                                        <div className={`skeleton b-3 h-2 mb-2`} style={{width: 195}}></div>
                                    </div>
                                    <div className={`skeleton b-4`} style={{width: 198, height: 40}}></div>
                                </div>
                            )})}
                        </div> 
                    </div> 
                </div>
                
                {FilterSearchRequests(searchData?.user_requests ?? recRequests)?.length > 3 &&
                (FilterSearchRequests(searchData?.user_requests ?? recRequests).map((elem: any, index: number) => { return (index < 3 ? null :
                <div className={`w-flex`} onClick={() => { 
                        // context.setProfilePopup({
                        //     ...elem.user,
                        //     onlyRequest: elem.card, 
                        // }) 
                        if (context.profile?.subscription?.is_subscription_active) {  
                            if (window.getSelection()?.type !== "Range") {
                                const newWindow: WindowProxy | null = window.open(`/request/${elem.card?.id}`, '_blank');
                                if (newWindow) {
                                    newWindow.focus();
                                }
                            }
                        } else {
                            context.setActivateSubscriptionPopup(true);
                        }
                    }} key={index}>
                    <FeedRequestCardMobile className={`mb-1 w-flex`} profile={elem.user} request={elem.card}  onRequested={(id: string) => {
                                                setRecRequests((val: any[]) => SetDict(val, [index, 'is_requested'], (val[index]?.is_requested ?? []).concat([id])));
                                            }}
                                            mobile={true} onlyValid={true} index={index} setFilterByIndustry={setFilterByIndustry} selectedTags={filterByIndustry}/>
                </div>)}))}

                <div className={`w-flex py-3 row center`} id={`people-end-element`} style={{
                        transition: `opacity 0.2 ease-in-out`,
                        // opacity: 0,
                    }}>
                    <p data-size={5} className={`center semibold`} data-color={`gray`}>
                        <FormattedMessage id="feed_end_1"/><br/>
                        <FormattedMessage id="feed_end_2"/>
                    </p>
                </div>
            </> : <>
                <div className={`skeleton w-flex b-3 h-8 mb-2`}></div>            
                <div className={`skeleton w-flex b-3 mb-3 h-9`}></div>
                <div className={`skeleton w-flex b-3 mb-6 h-10`}></div>
            </>)}

            {context.tab === Infinity && 
            (!!feed && !!backFeed ? <>                
                <div className={`block label btn-block mobile w-flex mb-2`} data-color={rndColor[0]}>
                    <div className={`row nowrap`}>
                        <p data-color={rndColor[2]}><FormattedMessage id="feed_similar"/></p>
                        {!props.recommendationsCommon?.length &&
                        <Loading color={rndColor[2]} width={20} height={20}/>}
                    </div>
                    <div className={`row nogap nowrap min-w-flex neg-mx-3 mt-2`}>
                        <div className={`row x-auto left top noshrink nowrap nogap w-flex px-3`}>
                            {props.recommendationsCommon?.length ?
                            props.recommendationsCommon.map((elem: any, index: number) => {return (
                                <SmallUserBlock profile={elem?.user} index={index} className={`${!!index ? `ml-2` : ``}`} 
                                                setRecommendations={props.setRecommendationsCommon} mobile={true}
                                                onSubscribe={() => { 
                                                    FeedRequest(
                                                        cookie['user_id_cookie'], 
                                                        setFeed, setBackFeed, 0, 
                                                        () => { setFeedPage(1) },
                                                    )
                                                }} onClick={() => { 
                                                    // context.setProfilePopup(elem.user);
                                                    const newWindow: WindowProxy | null = window.open(`/profile/${elem?.user?.id}`, '_blank');
                                                    if (newWindow) {
                                                        newWindow.focus();
                                                    } 
                                                }} key={index}/>
                            )}) :
                            [1, 2, 3].map((elem, index) => {return (
                                <div className={`block b-3 p-2 column top ${!!index ? `ml-2` : ``}`} key={index}>
                                    <NewAvatar size={0} className={`mb-2 circled`}/>
                                    <div style={{height: 61}}>
                                        <div className={`skeleton b-4 h-3 mb-1`} style={{width: 180}}></div>
                                        <div className={`skeleton b-3 h-2 mb-2`} style={{width: 195}}></div>
                                    </div>
                                    <div className={`skeleton b-4`} style={{width: 198, height: 40}}></div>
                                </div>
                            )})}
                        </div> 
                    </div> 
                </div>
                
                {!!recRequests?.length &&
                (!!recRequests && recRequests.map((elem: any, index: number) => { return (
                <div className={`w-flex`} onClick={() => { 
                        // context.setProfilePopup({
                        //     ...elem.user,
                        //     onlyRequest: elem.card, 
                        // }) 
                        if (window.getSelection()?.type !== "Range") {
                            const newWindow: WindowProxy | null = window.open(`/request/${elem.card?.id}`, '_blank');
                            if (newWindow) {
                                newWindow.focus();
                            }
                        }
                    }} key={index}>
                    <FeedRequestCardMobile className={`mb-1 w-flex`} profile={elem.user} request={elem.card}  onRequested={(id: string) => {
                                                setRecRequests((val: any[]) => SetDict(val, [index, 'is_requested'], (val[index]?.is_requested ?? []).concat([id])));
                                            }}
                                            mobile={true} onlyValid={true} index={index} setFilterByIndustry={setFilterByIndustry}/>
                </div>)}))}

                <div className={`w-flex py-3 row center`} id={`people-end-element`} style={{
                        transition: `opacity 0.2 ease-in-out`,
                        // opacity: 0,
                    }}>
                    <p data-size={5} className={`center semibold`} data-color={`gray`}>
                        <FormattedMessage id="feed_end_1"/><br/>
                        <FormattedMessage id="feed_end_2"/>
                    </p>
                </div>
            </> : <>
                <div className={`skeleton w-flex b-3 h-8 mb-2`}></div>            
                <div className={`skeleton w-flex b-3 mb-3 h-9`}></div>
                <div className={`skeleton w-flex b-3 h-10`}></div>
            </>)}

            {/* {!!showProfile &&
            <ProfilePopupMobile user={showProfile} setUser={setShowProfile}
                                onSubscribe={() => { 
                                    FeedRequest(
                                        cookie['user_id_cookie'], 
                                        setFeed, setBackFeed, 0, 
                                        () => { setFeedPage(1) },
                                    )
                                }}/>}            */}

            {hintPopup?.show &&
            <PopupHintMobile show={hintPopup?.show} setShow={(show: boolean) => { setHintPopup(val => SetDict(val, [`show`], show)) }} onClick={hintPopup?.onClick}
                             title={hintPopup?.title} description={hintPopup?.description} buttonTitle={hintPopup?.buttonTitle} close={hintPopup?.close}/>} 
        </div>
    )
}

export const ProfileRequestsMobile = (props: any) => {
    const context = useContext(ProfileContext);
    const globalContext = useContext(GlobalContext);
    const navigate = useNavigate();

    return (
        <div className={`w-flex px-3 pt-2`}>
        {props.loaded ? <>
            <div className={`block px-3 pb-3 b-3 w-flex mb-3 y-auto`} data-border={context.profile?.requests?.length === 0 ? `coral-2` : undefined}
                    data-color={context.profile?.requests?.length === 0 ? `extra-light-coral` : undefined}>   
                <div className={`row w-flex mt-3`} hidden={context.profile?.requests?.length === 0}>
                    <div className={`row left nowrap`}>
                        <div className={`block min-w-3 h-3 centered b-2`} data-color={`black`} hidden={!context.profile?.requests?.length}>
                            <p data-size={6} className={`px-1`}>{context.profile?.requests?.length}</p> 
                        </div>
                        <p data-size={5} className={`semibold seminarrow`}><FormattedMessage id="my_requests"/></p>
                    </div>
                    <button className={`btn-symbol h-3 w-3 centered`} hidden={context.profile?.requests?.length < 2}
                            onClick={() => { 
                                // setRequestNumber(null);
                                context.setAddRequestPopup(true);
                            }}>
                        <img src={PlusBlack} alt={`+`}></img>
                    </button>
                </div>

                <div className={`block p-3 b-2 clickable w-flex centered mt-3`} hidden={!(context.profile?.requests?.length < 2)} style={{
                            position: `relative`, height: 235,
                        }} data-color={context.profile?.requests?.length === 0 ? undefined : `light-gray`} onClick={() => { 
                            // setRequestNumber(null);
                            context.setAddRequestPopup(true);
                        }}>
                    <div style={{
                        position: `absolute`, top: 0, left: 0, bottom: 0, right: 0,
                    }} className={`block b-2`} data-color={`transparent`}
                        onTouchStart={() => {
                            const editBlock = document.querySelector(`#request-add-block-left`) as HTMLDivElement;
                            if (editBlock) {
                                editBlock.setAttribute(`data-color`, `black-5-opacity`);
                            }
                        }} onTouchEnd={() => {
                            const editBlock = document.querySelector(`#request-add-block-left`) as HTMLDivElement;
                            if (editBlock) {
                                editBlock.setAttribute(`data-color`, `transparent`);
                            } 
                        }} id={`request-add-block-left`}>
                    </div>
                    <div className={`column top`}>
                        <div className={`btn-symbol h-3 w-3 centered mb-1`}>
                            <img src={PlusBlack} alt={`+`}></img>
                        </div>
                        <p data-size={6} className={`semibold center px-3`} data-color={`black-40-opacity`}>
                            {context.profile?.requests?.length === 0 ? 
                            `Post at least one request to receive tailored matches from ${CommunityLogo[globalContext.community]?.title ?? "Osmos"}` : 
                            <FormattedMessage id="post_a_new_request"/>}
                        </p>
                    </div>
                </div>

                {!!context.profile?.requests?.length &&
                context.profile?.requests.map((elem: any, index: number) => {return (
                    <div className={`block p-3 b-2 w-flex column left mt-3`} key={index} style={{
                            position: `relative`, height: 235, overflow: `hidden`,
                        }}
                        onClick={() => {
                            navigate(`/request/${elem?.id}`);
                        }}>
                        <div className={`block`} data-color={typeof elem?.style?.background === `number` ? RequestColors2[elem?.style?.background]?.color : `light-gray`} 
                            style={{
                                position: `absolute`, top: 0, left: 0, bottom: 0, right: 0, pointerEvents: `none`,
                                opacity: typeof elem?.style?.background === `number` ? RequestColors2[elem?.style?.background]?.bgOpacity : 1,
                            }}></div>
                        <div className={`w-flex`} style={{position: `relative`, zIndex: 1}}>
                            <p data-size={6} data-color={`black-40-opacity`}>{DateFromString(elem?.created_at)}</p>
                            <p data-size={6} data-color={`black`} className={`my-1`} data-lines={8}>
                                {(elem.answers[0] ? elem.answers[0] : `Add description for your request...`).trim()
                                .split(`\n`).map((elem_: string, index_: number) => {return (<Fragment key={index_}>
                                    <span className={`article`}>{elem_}</span>
                                </Fragment>)})}
                            </p>
                        </div>
                        {/* {!!getRec(elem?.id) && index !== requestNumber &&
                        <div className={`block label btn-rectangle-40 mobile p-right w-flex row nowrap`}
                                style={{position: `relative`, zIndex: 1}}>
                            <div className={`row left nogap nowrap`}>
                                {getRec(elem?.id).map((elem_: any, index_: number) => {return (
                                    <NewAvatar name={elem_?.name} avatar={elem_?.avatar?.link} type={elem_?.avatar?.style}
                                            size={4} className={`neg-ml-2 neg-my-2`} key={index_}/>
                                )})}
                                <p data-size={6} className={`semibold seminarrow ml`}>{`And 7 more`}</p>
                            </div>
                            <div className={`btn-symbol w-3 h-3 centered`}>
                                <img className={`not-scale w-3 neg-m`} src={AngleRightBlack2} alt={`>`}></img>
                            </div>
                        </div>} */}
                    </div>
                )})}
            </div>

            {context.profile?.requests?.length === 0 &&
            <div className={`block label btn-block mobile w-flex mb-3`}>
                <p><FormattedMessage id="interesting_people"/></p>
                <div className={`row nogap nowrap min-w-flex neg-mx-3 mt-2`}>
                    <div className={`row x-auto left top noshrink nowrap nogap w-flex px-3`}>
                        {context.recommendationsCommon?.length ?
                        context.recommendationsCommon.map((elem: any, index: number) => {return (
                            <SmallUserBlock profile={elem?.user} index={index} className={`${!!index ? `ml-2` : ``}`} 
                                            setRecommendations={context.setRecommendationsCommon} mobile={true} key={index}
                                            color={`light-gray`}/>
                        )}) :
                        [1, 2, 3].map((elem, index) => {return (
                            <div className={`block b-3 p-2 column top ${!!index ? `ml-2` : ``}`} 
                                    key={index} data-color={`light-gray`}>
                                <NewAvatar size={0} className={`mb-2 circled`}/>
                                <div style={{height: 61}}>
                                    <div className={`skeleton b-4 h-3 mb-1`} style={{width: 180}}></div>
                                    <div className={`skeleton b-3 h-2 mb-1`} style={{width: 195}}></div>
                                </div>
                                <div className={`skeleton b-4`} style={{width: 198, height: 40}}></div>
                            </div>
                        )})}
                    </div> 
                </div> 
            </div>} 
        </> :
        <div className={`skeleton w-flex mb-3 h-10 b-3`}></div>}
    </div>
    )
}

export const ProfileChatsMobile = (props: any) => { 
    const navigate = useNavigate();
    const [ cookie ] = useCookies(['user_id_cookie']);
    const context = useContext(ProfileContext);
    const globalContext = useContext(GlobalContext);

    const [ loaded, setLoaded ] = useState<boolean>(false);
    // const [ notificationNumber, setNotificationNumber ] = useState<number>(0)
    
    const [ match_, setMatch_ ] = useState<any | null>();
    const [ chats, setChats ] = useState<any[]>([]);
    const [ offers, setOffers ] = useState<any[]>([]);
    const [ requested, setRequested ] = useState<any[]>([]);
    const [ newMessage, setNewMessage ] = useState<string>(``);
    // const [ editMessage, setEditMessage ] = useState<boolean>(false);

    const [ offerPopup, setOfferPopup ] = useState<any | null>(null);    
    const [ waitAcceptOrReject, setWaitAcceptOrReject ] = useState<boolean>(false);

    const [ ws, setWs ] = useState<WebSocket | null>(null);
    const [ receivedMessage, setReceivedMessage ] = useState<any>({});
    const [ newChatId, setNewChatId ] = useState<string | null>(null); 

    const [ newRequestedWait, setNewRequestedWait ] = useState<boolean>(false);
    
    const onSubmit = (e: any) => {
        e.preventDefault();
        if (offerPopup?.items?.length === 0) {
            setNewRequestedWait(true);
            NewOffer({
                user_id: offerPopup.user?.id,
                pair_id: offerPopup.pair?.id,
                card_id: offerPopup.refer_to_request?.id ?? null,
                text: newMessage,
            }, () => {
                setNewRequestedWait(false);
                setRequested((val: any[]) => SetDict(val, [val.findIndex((rec: any) => rec.pair?.id === offerPopup?.pair?.id)], {
                    created_at: Date.now() / 1000,    
                    items: [{
                        created_at: Date.now() / 1000,
                        card: context.newChatOffer?.refer_to_request,
                        text: newMessage,
                    }],
                    pair: context.newChatOffer?.pair,
                    user: context.newChatOffer?.user,
                }))
                setOfferPopup((val: any) => SetDict(SetDict(val, [`refer_to_request`], undefined), ["items"], [{
                    created_at: Date.now() / 1000,
                    card: context.newChatOffer?.refer_to_request,
                    text: newMessage,
                }]))
                setNewMessage("");
                context.setNewChatOffer(undefined);
                // setEditMessage(false);
            }, () => { setNewRequestedWait(false) })
        }
    }    

    useEffect(() => {      
        if (chats) {
            sessionStorage.setItem("chats", JSON.stringify(chats));
        }   
        if (offers) {
            sessionStorage.setItem("offers", JSON.stringify(offers));
        }   
        if (chats) {
            sessionStorage.setItem("requested", JSON.stringify(requested));
        }
    }, [chats, offers, requested])

    useEffect(() => {
        if (requested && offerPopup?.items?.length === 0) {
            setNewMessage(offerPopup?.is_can_help ? `Hi! I can help you with...` : `I hope you're doing well! I'm looking for … and thought you'd be the perfect person to help out!`);
        } else {
            setNewMessage('');
        } 
    }, [requested, offerPopup])
    
    useEffect(() => {
        if (chats) {
            // setNotificationNumber((chats ?? []).reduce((init, chat) => (init + (chat?.unread ?? 0)), 0));
            setLoaded(true); // no need match

            const old_chats = [...chats ?? []].sort(
                (a, b) => DateToTimestamp(b.created_at) - DateToTimestamp(a.created_at)
            );
            
            if (old_chats?.length > 0) {
                ProfileRequest(
                    old_chats[0]?.user_id, 
                    setMatch_, 
                    () => { setLoaded(true) }
                );
            } else {
                setLoaded(true);
            }
        }
    }, [chats])

    useEffect(() => {
        if (match_) {
            setLoaded(true);
        }
    }, [match_]) 

    useEffect(() => {
        if (ws) {
            ws.onmessage = (e: MessageEvent) => {                              
                const message = JSON.parse(e.data);
                setReceivedMessage(message);  
            }
            ws.onclose = (e: CloseEvent) => {
                setTimeout(() => {
                    setWs(new WebSocket(`${GetWebsocketUrl()}s`));
                }, 1000)
            }
        }
    }, [ws]) 

    useEffect(() => {
        if (receivedMessage['event_type'] === 'send') {     
            const idx = chats.findIndex((elem: any) => elem?.id === receivedMessage['chat_id']);                        
            setChats((val: any) => SetDict(val, [idx, 'last_message'], receivedMessage[`message`]));                   
            setChats((val: any) => SetDict(
                val, 
                [idx, 'unread'], 
                val[idx].unread + (receivedMessage['from_client'] === cookie[`user_id_cookie`] ? 0 : 1)
            ));
        } 
        if (receivedMessage['event_type'] === 'edit') {                              
            const idx = chats.findIndex((elem: any) => elem?.id === receivedMessage['chat_id']);  
            if (receivedMessage[`message_id`] === chats[idx][`last_message`]) {                      
                setChats((val: any) => SetDict(val, [idx, 'last_message'], receivedMessage[`message`]));
            }
        } 
        if (receivedMessage['event_type'] === 'read') {                              
            const idx = chats.findIndex((elem: any) => elem?.id === receivedMessage['chat_id']); 
            if (cookie[`user_id_cookie`] === receivedMessage[`from_client`]) { 
                setChats((val: any) => SetDict(val, [idx, 'unread'], 0));
            }
        }
        if (receivedMessage['event_type'] === 'delete') { 
            const idx = chats.findIndex((elem: any) => elem?.id === receivedMessage['chat_id']);
            if (receivedMessage[`message_id`] === chats[idx][`last_message`][`id`]) {  
                ChatsRequest(cookie['user_id_cookie'], setChats);
            }
        }         
    }, [receivedMessage])

    useEffect(() => {
        if (ws) {
            ws.onclose = null;
            ws.onmessage = null;
            ws?.close();
        }
        setWs(new WebSocket(`${GetWebsocketUrl()}s`));
    }, [chats?.length ?? 0])

    useEffect(() => {
        if (newChatId) {            
            navigate(`/messenger/${newChatId}`); 
        }
    }, [newChatId])

    useEffect(() => {
        ChatsRequest(cookie[`user_id_cookie`], props.setChats);        
        OffersRequest(cookie[`user_id_cookie`], props.setOffers, props.setRequested);
                    
        const savedChats = sessionStorage.getItem("chats");
        const savedOffers = sessionStorage.getItem("offers");
        const savedRequested = sessionStorage.getItem("requested");
        if (savedChats) {
            setChats(JSON.parse(savedChats));
        }
        if (savedOffers) {
            setOffers(JSON.parse(savedOffers));
        }
        if (savedRequested) {
            setChats(JSON.parse(savedRequested));
        }

        return () => {
            if (ws) {
                ws.onclose = null;
                ws.onmessage = null;
                ws?.close();
            }

            context.setNewChatOffer(undefined);
            props.setRequested((val: any[]) => val ? val.filter(elem => !elem.is_new_requested) : val);
        }
    }, [])
    
    useEffect(() => {     
        if (context.newChatOffer) {
            const chat = (props.chats ?? []).find((val: any) => val?.user_id === context.newChatOffer?.pair?.id);
            const requestedIdx = (props.requested ?? []).findIndex((val: any) => val?.pair?.id === context.newChatOffer?.pair?.id);
            const offerIdx = (props.offers ?? []).findIndex((val: any) => val?.user?.id === context.newChatOffer?.pair?.id);
            if (chat) {
                setRequested(props.requested);
                setChats(props.chats);
                setOffers(props.offers);
                sessionStorage.setItem("messenger_new_chat_offer", JSON.stringify(context.newChatOffer))
                navigate(`/messenger/${chat?.id}`);
                context.setNewChatOffer(undefined);                
            } else if (requestedIdx > -1) {
                setChats(props.chats);
                setRequested(SetDict(props.requested, [requestedIdx, "newChatOffer"], context.newChatOffer));
                setOffers(props.offers);
                setOfferPopup(props.requested[requestedIdx]);
                context.setNewChatOffer(undefined);
            } else if (offerIdx > -1) {
                setChats(props.chats);
                setRequested(props.requested);
                setOffers(SetDict(props.offers, [offerIdx, "newChatOffer"], context.newChatOffer))
                setOfferPopup(props.offers[offerIdx]);
                context.setNewChatOffer(undefined);
            } else {
                setChats(props.chats);
                setRequested([context.newChatOffer].concat(props.requested ?? []));
                setOffers(props.offers);
                setOfferPopup(context.newChatOffer);
            }
        } else {
            setChats(props.chats);
            setRequested(props.requested);
            setOffers(props.offers);
        }
    }, [props.chats, props.requested, props.offers])   
    
    return(
        <>     
            {!(context.tab - 0) && ((
                chats && chats?.length === 0 &&
                requested && requested?.length === 0 &&
                offers && offers?.length === 0 
            ) ?
                <div className={`px-3 w-flex mt-2`}>
                    <MessageCardMobile profile={props.profile} type={`chat`}/> 
                </div> :
                <div className={`pt-2 w-flex`}>
                    {loaded ?
                    <ChatListMobile chats={chats} setChats={setChats} 
                                    setOfferPopup={setOfferPopup}
                                    chatsRequested={requested} setChatsRequested={setRequested}
                                    chatsOffers={offers} setChatsOffers={setOffers}/> :
                    <div className={`w-flex px-3`} style={{minHeight: `calc(100vh - 160px)`}}>
                        <div className={`skeleton w-flex b-3 h-9 mb-3`}></div>                  
                    </div>}
                </div>
            )} 

            {!(context.tab - 1) && ((props.offers && props.offers?.length === 0) ?
                <div className={`px-3 w-flex`}>
                    <MessageCardMobile profile={props.profile} type={`offer`}/> 
                </div> :
                (loaded ?
                <OfferListMobile offers={offers} setOffers={setOffers} setOfferPopup={setOfferPopup}/> :
                <div className={`w-flex px-3`} style={{minHeight: `calc(100vh - 160px)`}}>
                    <div className={`skeleton w-flex b-3 h-9 mb-3`}></div>                  
                </div>)
            )} 

            {!(context.tab - 2) && ((props.requested && props.requested?.length === 0) ?
                <div className={`px-3 w-flex`}>
                    <MessageCardMobile profile={props.profile} type={`requested`}/> 
                </div> :
                (loaded ?
                <OfferListMobile offers={requested} setOffers={setRequested} setOfferPopup={setOfferPopup} requested={true}/> :
                <div className={`w-flex px-3`} style={{minHeight: `calc(100vh - 160px)`}}>
                    <div className={`skeleton w-flex b-3 h-9 mb-3`}></div>                  
                </div>)
            )}            

            {offerPopup &&
            <div className={`block column y-auto`} data-color={`light-gray`} style={{
                position: `fixed`, top: 0, bottom: 0, left: 0, right: 0, zIndex: 200,
            }}>
                <div className={`w-flex`}>
                    <div className={`block block p-3 pb-2 pt-2 w-flex`} data-color={`light-gray`} style={{
                        position: 'fixed',
                        zIndex: 48,
                        boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.04)`
                    }}>
                        <div className={`row nowrap nogap w-flex`}>
                            <button className={`btn mobile row left nowrap noscroll w-4`} onClick={() => { setOfferPopup(null) }}>
                                <img src={AngleRightBlack} alt={`>`} className={`w-3`} style={{transform: `rotate(180deg)`}}></img>
                            </button>
                            {loaded ?
                            <a href={`/profile/${(offerPopup?.user?.id === cookie[`user_id_cookie`] ? offerPopup?.pair : offerPopup?.user)?.id}`}  className={`mobile col-7`} target={`_blank`}>
                                <p className={`text-5 semibold center`} data-color={`black`}
                                    style={{
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    }}>
                                    {(offerPopup?.user?.id === cookie[`user_id_cookie`] ? offerPopup?.pair : offerPopup?.user)?.name ?? ``}
                                </p>
                            </a> : 
                            <div className={`skeleton h-3 w-7 b-3`}></div>} 
                            {loaded ?
                            <NewAvatar size={4} type={(offerPopup?.user?.id === cookie[`user_id_cookie`] ? offerPopup?.pair : offerPopup?.user)?.avatar?.style ?? 0} name={(offerPopup?.user?.id === cookie[`user_id_cookie`] ? offerPopup?.pair : offerPopup?.user)?.name ?? ``}
                                        avatar={(offerPopup?.user?.id === cookie[`user_id_cookie`] ? offerPopup?.pair : offerPopup?.user)?.avatar?.link}/> :
                            <div className={`skeleton w-4 h-4 b-1`}></div>}
                        </div>
                    </div>

                    <div className={`w-flex`} style={{
                            paddingTop: 70, 
                            paddingBottom: offerPopup?.user?.id === cookie[`user_id_cookie`] ? 134 : 134,
                        }}>
                        {offerPopup?.items && offerPopup?.items.map((elem: any, index: number) => {return (
                        <div className={'w-flex'} key={index}>
                            {(!index || DateFromString(elem?.created_at) !== DateFromString(offerPopup?.items[index - 1]?.created_at)) && 
                            <div className={`row center my-2`}>
                                <div className={`block label btn-circled-24 mobile`} data-color={`black-20-opacity`}>
                                    <p data-color={'white'}>{DateFromString(offerPopup?.created_at)}</p>
                                </div>
                            </div>}
                            <div className={`row px-3 pt pb ${offerPopup?.user?.id === cookie[`user_id_cookie`] ? `right` : `left`} top w-flex nogap nowrap noscroll mb-1 message`}>
                                <div className={`block b-2 p-2`} style={{
                                    width: 'calc(100% - 50px)',
                                    // borderRadius: offerPopup?.user?.id === cookie[`user_id_cookie`] ? `14px 14px 0 14px` : `14px 14px 14px 0`,
                                    borderRadius: (!offerPopup?.items[index + 1] || offerPopup?.items[index + 1]?.user?.id !== elem?.user?.id) ? (offerPopup?.user?.id === cookie['user_id_cookie'] ? `14px 14px 0 14px` : `14px 14px 14px 0`) : undefined,
                                    backgroundColor: offerPopup?.user?.id === cookie[`user_id_cookie`] ? `#E7EAF7` : undefined,
                                }}>
                                    <p className={`text-6 mb-1`} style={{
                                        overflowWrap: 'break-word',
                                    }} data-color={`black`}>
                                        {(elem?.text ?? '').trim().split('\n').map((elem_: string, index: number) => {return (
                                            <Fragment key={index}>
                                                {elem_.split(URLRegExp).filter((elem: string) => !!elem).map((elem__, index__) => {return (
                                                    URLRegExp.test(elem__) ?
                                                    <a key={index__} onClick={e => { e.stopPropagation() }} style={{
                                                        fontWeight: 400,
                                                        position: `relative`,
                                                        zIndex: 2,
                                                    }} href={MakeValidURL(elem__)} target={`_blank`}>
                                                        <span data-color={`blue`}>{elem__}</span>
                                                    </a> :
                                                    <span key={index__} style={{
                                                        fontWeight: 400,
                                                    }}>
                                                        {elem__}
                                                    </span>
                                                )})}
                                                <br/>
                                            </Fragment>
                                        )})}
                                    </p>
                                    {!!elem?.card &&
                                    // <div className={`block w-flex p-2 b-2`} data-color={`transparent`} style={{border: 'solid 1px #D9D9D9'}}>
                                    //     <div className={`row left top nowrap mb-1`}>
                                    //         <NewAvatar name={elem?.card?.user?.name} 
                                    //                 type={elem?.card?.user?.avatar?.style} size={3}
                                    //                 avatar={elem?.card?.user?.avatar?.link}/>
                                    //         <div>
                                    //             <p data-size={6} className={`semibold seminarrow`} data-color={`black`}>
                                    //                 {elem?.card?.user?.name}
                                    //             </p>
                                    //             <p data-size={7} data-color={`black-40-opacity`} data-lines={1}>
                                    //                 {elem?.card?.user?.occupation}
                                    //             </p>
                                    //         </div>
                                    //     </div>
                                    //     <p data-size={6} style={{
                                    //         overflowWrap: 'break-word',
                                    //     }} data-color={`black`}>
                                    //         {(elem?.card?.answers[0] ?? '').trim().split('\n').map((elem_: string, index: number) => {return (
                                    //             <Fragment key={index}>
                                    //                 {elem_.split(URLRegExp).filter((elem: string) => !!elem).map((elem__, index__) => {return (
                                    //                     URLRegExp.test(elem__) ?
                                    //                     <a key={index__} onClick={e => { e.stopPropagation() }} style={{
                                    //                         fontWeight: 400,
                                    //                         position: `relative`,
                                    //                         zIndex: 2,
                                    //                     }} href={MakeValidURL(elem__)} target={`_blank`}>
                                    //                         <span data-color={`blue`}>{elem__}</span>
                                    //                     </a> :
                                    //                     <span key={index__} style={{
                                    //                         fontWeight: 400,
                                    //                     }}>
                                    //                         {elem__}
                                    //                     </span>
                                    //                 )})}
                                    //                 <br/>
                                    //             </Fragment>
                                    //         )})}
                                    //     </p>
                                    // </div>
                                    <FeedRequestCardMobile request={elem?.card} onboardingV={true}/>}
                                    <div className={`row nowrap noscroll`}>
                                        <div className={`row nogap nowrap noscroll`}>
                                        </div>
                                        <div className={`row nowrap nogap noscroll`}>
                                            <p className={`text-6`} data-color={`black-40-opacity`}>{TimeFromString(elem?.created_at)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)})}
                    </div>
                </div>

                <div className={`block sticky-bottom w-flex p-3 pt-1 pb-2`} data-color={`light-gray`}
                    style={{
                        boxShadow: `0px -1px 2px 0px rgba(0, 0, 0, 0.04)`,
                        zIndex: 48, position: `fixed`, bottom: 0,
                    }} onClick={e => { e.stopPropagation() }}>
                    {offerPopup?.pair?.id !== cookie[`user_id_cookie`] ? <>
                    {offerPopup?.refer_to_request &&
                    // <div className={`block w-flex p-2 b-2 mb-1`} data-color={`transparent`} 
                    //      style={{border: 'solid 1px #D9D9D9'}}>
                    //     <div className={`row left top nowrap mb-1`}>
                    //         <NewAvatar name={offerPopup?.refer_to_request?.user?.name} 
                    //                 type={offerPopup?.refer_to_request?.user?.avatar?.style} size={3}
                    //                 avatar={offerPopup?.refer_to_request?.user?.avatar?.link}/>
                    //         <div>
                    //             <p data-size={6} className={`semibold seminarrow`} data-color={`black`}>
                    //                 {offerPopup?.refer_to_request?.user?.name}
                    //             </p>
                    //             <p data-size={7} data-color={`black-40-opacity`} data-lines={1}>
                    //                 {offerPopup?.refer_to_request?.user?.occupation}
                    //             </p>
                    //         </div>
                    //     </div>
                    //     <p data-size={6} style={{
                    //         overflowWrap: 'break-word',
                    //     }} data-color={`black`}>
                    //         {(offerPopup?.refer_to_request?.answers[0] ?? '').trim().split('\n').map((elem_: string, index: number) => {return (
                    //             <>
                    //                 {elem_.split(URLRegExp).filter((elem: string) => !!elem).map((elem__, index__) => {return (
                    //                     URLRegExp.test(elem__) ?
                    //                     <a key={index__} onClick={e => { e.stopPropagation() }} style={{
                    //                         fontWeight: 400,
                    //                         position: `relative`,
                    //                         zIndex: 2,
                    //                     }} href={MakeValidURL(elem__)} target={`_blank`}>
                    //                         <span data-color={`blue`}>{elem__}</span>
                    //                     </a> :
                    //                     <span key={index__} style={{
                    //                         fontWeight: 400,
                    //                     }}>
                    //                         {elem__}
                    //                     </span>
                    //                 )})}
                    //                 <br/>
                    //             </>
                    //         )})}
                    //     </p>
                    // </div>
                    <FeedRequestCardMobile request={offerPopup?.refer_to_request} onboardingV={true} className={`mb-2`}/>}
                    <form className={`block b-2 row bottom nowrap nogap noscroll`} 
                        onSubmit={e => {
                                e.preventDefault();
                                onSubmit(e);
                        }}>
                        <div className={`chat-input mobile`}>
                            <p className={`w-flex`}>
                                {newMessage.split('\n').map(elem => {return (<>
                                    {elem}<br/>
                                </>)})}
                            </p>                
                            <textarea className={`block w-flex h-flex b-2`} data-size={6} 
                                data-color={`white`} placeholder={messages[globalContext.locale].message} value={newMessage} 
                                onChange={e => { 
                                    setNewMessage(e.target.value); 
                                }}></textarea>
                        </div>
                        {!!newMessage && (newRequestedWait ?
                        <div className={`py-2 pr-2 row`} style={{marginLeft: -38, position: `relative`}}>
                            <Loading color={`black`} width={24} height={24}/>
                        </div> :
                        <button type={`submit`} className={`h-3 my-2`} style={{
                                    marginLeft: -38, position: `relative`,
                                    opacity: (offerPopup?.items?.length || !context.isSubscriptionActive) > 0 ? 0.4 : 1,
                                }}
                                disabled={offerPopup?.items?.length > 0}>
                            <img src={SendMsgBlack} alt={``} className={`w-3 h-3 mr-2`}></img>
                        </button>)}
                    </form>
                    {(!context.isSubscriptionActive && !!newMessage) ? 
                    <p data-size={7} data-color={`coral`} className={`pt px-1`}>
                        {`You cannot send messages until your`}<br/>{`subscription is not active.`}
                    </p> : 
                    (offerPopup?.items?.length > 0 && !!newMessage &&
                    <p data-size={7} data-color={`coral`} className={`pt px-1`}>
                        {`You can only send one message here.`}<br/>{`Please wait until the person accepts the connection.`}
                    </p>)}
                    </> : <>
                    <button className={`block mobile b-3 btn-color w-flex mb-1`} data-color={`black`}
                            onClick={() => {
                                ReactOffer({
                                    user_id: offerPopup?.user?.id,
                                    pair_id: cookie[`user_id_cookie`],
                                    card_id: offerPopup?.items[0]?.card?.id ?? null,
                                    action: `accept`,
                                }, setNewChatId, () => {
                                    setWaitAcceptOrReject(false);
                                    setOfferPopup(null);                                    
                                    OffersRequest(cookie[`user_id_cookie`], props.setOffers);
                                }, () => { setWaitAcceptOrReject(false) })
                            }} disabled={waitAcceptOrReject}>
                        <p className={`semibold center`}><FormattedMessage id="accept_and_answer"/></p>
                    </button>
                    <button className={`block mobile b-3 btn-color w-flex`} data-color={`transparent`}
                            onClick={() => {
                                ReactOffer({
                                    user_id: offerPopup?.user?.id,
                                    pair_id: cookie[`user_id_cookie`],
                                    card_id: offerPopup?.items[0]?.card?.id ?? null,
                                    action: `reject`,
                                }, () => {}, () => {
                                    setWaitAcceptOrReject(false);
                                    setOfferPopup(null);
                                    OffersRequest(cookie[`user_id_cookie`], props.setOffers);
                                }, () => { setWaitAcceptOrReject(false) })
                            }} disabled={waitAcceptOrReject}>                                
                        {waitAcceptOrReject ?
                        <Loading color={`black`} width={19} height={19}/> :
                        <p className={`semibold center`} data-color={`black`}><FormattedMessage id="reject"/></p>}
                    </button></>}
                </div>
            </div>}
        </>)
}

const BecomeAMemberBlock = () => {
    const [ waitStripe, setWaitStripe ] = useState(false);

    return (
        <div className="w-flex">
            <div className="block bt-3 p-3 w-flex" data-color={"blue"}>
                <p data-size={4} className={`semibold seminarrow mb-1`}>
                    <FormattedMessage id="become_member"/><br/> 
                    {`and get your `}<span data-color={"green"}>{`expert match next Monday`}</span>
                </p>
                <p data-size={6} data-color={"white-40-opacity"}>
                    {`Becoming a paid member signifies your serious intentions respecting everyone's time.`}
                </p>
            </div>
            <div className="block bb-3 p-3 w-flex">
                <p data-size={6} className={`mb-2`}>
                    {`3 000 professionals from AI, VC, FinTech, MarTech, Education, Media, E-commerce, Web3, and more`}
                </p>

                {[
                    `Discover new ideas and insights.`,
                    `Get support.`,
                    `Find opportunities for collaboration and professional development.`,
                ].map((elem: string, index: number) => {return (
                    <div key={index} className={`row left gap-2 nowrap px-1 mb-2`}>
                        <img src={StarYellow} className={`w-3 h-3`} alt={``}></img>
                        <p data-size={6}>{elem}</p>
                    </div>
                )})}
                <p data-size={6}>
                    {`Connect with people who inspire and help each other.`}
                </p>

                <button className={`block btn-flex mobile row center w-8 mb-1 mt-3`} onClick={() => {
                    setWaitStripe(true);
                    OpenStripePortal(false, () => { setWaitStripe(false) }, `/profile/match?first_login=true&welcome=true`, 1)
                }} data-color={`black`}>
                    {waitStripe ? <Loading width={24} height={24} color={`white`}/> : 
                    <p>{`$19.99/month`}</p>}
                </button>
                <p data-size={7} data-color={`black-40-opacity`}>
                    {`Subscriptions renew automatically. Cancel anytime.`}
                </p>
            </div>
        </div>
    )
}  

export const ProfileMatchMobile = (props: any) => {
    const context = useContext(ProfileContext);
    const globalContext = useContext(GlobalContext);

    const [ loaded, setLoaded ] = useState<boolean>(false);
    const [ waitReadyStatus, setWaitReadyStatus ] = useState<boolean>(false);
    const [ feedBackPopup, setFeedBackPopup ] = useState<any>();

    const [ match, setMatch ] = useState<any>();
    const [ matchTab, setMatchTab ] = useState<number>(0);
    const [ isReadMore, setIsReadMore ] = useState<boolean[]>([]);  

    const [ toRateProfiles, setToRateProfiles ] = useState<any>();
    const [ toRateSelectedNum, setToRateSelectedNum ] = useState<number>();
    const [ toRateRatedNum, setToRateRatedNum] = useState<number>(0);
    const [ rateFlow, setRateFlow] = useState<"select" | "rate">();

    useEffect(() => {
        setLoaded(context.profile?.is_ready !== undefined && match !== undefined && (
            !context.profile?.subscription?.first_flow ||
            toRateProfiles !== undefined ||
            !!context.profile?.subscription?.first_flow_finished
        ));
    }, [context.profile, match, toRateProfiles])

    useEffect(() => {
        setWaitReadyStatus(false);
    }, [context.profile?.is_ready])

    useEffect(() => {
        setIsReadMore(matchTab === 1 ? OnlyValid(match?.requests).reduce((init: boolean[], elem: any) => {
            return init.concat([
                (document.querySelector(`#feed-card-about-${elem?.id}`) as HTMLDivElement)?.offsetHeight < (document.querySelector(`#feed-card-about-${elem?.id}`) as HTMLDivElement)?.scrollHeight
            ]);
        }, []) : [])

        const scrollBlock = document.querySelector(`#match-scroll-block`) as HTMLDivElement;
        if (scrollBlock) {
            scrollBlock.scrollTo({
                top: 0,
                behavior: `instant`,
            })
        }
    }, [match?.requests, matchTab]) 

    useEffect(() => {
        if (match && loaded) {
            localStorage.setItem("seen_as_match", match?.id)
        }
    }, [loaded, match])

    useEffect(() => {
        MatchRequest(setMatch, {
            404: () => { setMatch(null) },
        });

        ToRateRequest(setToRateProfiles, {0: () => {
            setToRateProfiles(null);
        }});
    }, [])

    useEffect(() => {
        if (toRateProfiles?.length > 1) {
            setRateFlow(Math.floor(Math.random() * 3) === 0 ? "select" : 'rate');
        }
    }, [toRateProfiles?.length])

    useEffect(() => {
        if (rateFlow) {
            toRateProfiles?.forEach((elem: any, index: number) => {
                const aboutElem = document.querySelector(`#rate-user-about-${elem?.user?.id}`) as HTMLPreElement;
                if (aboutElem) {
                    setToRateProfiles((val: any) => SetDict(
                        val, [index, "user", "readMore"], 
                        aboutElem.scrollHeight > (rateFlow === "select" ? 79 : 90),
                    ))
                }
            })
        }
    }, [rateFlow, toRateSelectedNum, toRateRatedNum])

    return (
        <div className={`row center w-flex pt-2 px-3`}>
            <div className={`w-10 sm-w-flex pb-6 column`} style={{
                flexDirection: match?.is_met ? `column-reverse` : `column`,
                gap: 24,
            }}>
                {loaded ? <>                
                {!!match ? (match?.seen_as_match ?
                <div className={`block b-3 p-2 column left w-flex`}>
                    <a className={`row left gap-2 nowrap w-flex mb-2`} href={`/profile/${match?.unique_id ?? match?.id}`}>
                        <NewAvatar name={match?.name} avatar={match?.avatar?.link} 
                                size={4} type={0}/>  
                        <div className={`w-flex`}> 
                            <p data-size={5} data-color={`black`} className={`semibold seminarrow`}>
                                {match?.name}
                            </p>
                            <p data-size={6} data-color={`black-40-opacity`} data-lines={2}>
                                {match?.occupation}
                            </p>
                        </div>
                    </a> 
                    {match?.is_met ?
                    <button className={`block btn-flex mobile w-flex row center`} data-color={`light-gray`} 
                            onClick={() => { setFeedBackPopup(match) }}>
                        <p><FormattedMessage id="match_feedback"/></p>
                    </button> :
                    <button className={`block btn-flex mobile w-flex row center`} data-color={`green`}
                            onClick={() => {
                                context.setNewChatOffer({
                                    created_at: Date.now() / 1000,    
                                    items: [],
                                    pair: match,
                                    user: context.profile,
                                    is_new_requested: true, 
                                    is_chat_offer: true,
                                });
                                context.setPage(2, 0);
                                context.setTab(0, 2);
                            }}>
                        <p><FormattedMessage id="match_arrange"/></p>
                    </button>}     
                </div> :
                <div className={`block b-3 p-2 w-flex column left`}>
                    <div className={`w-flex`}>
                        <div className={`row left top gap-2 nowrap mb-3`}>
                            <NewAvatar name={match?.name} avatar={match?.avatar?.link} 
                                    size={5} className={`circled`} type={0}/>  
                            <div> 
                                <p data-size={4} className={`semibold seminarrow mb`}>
                                    {match?.name}
                                </p>
                                <p data-size={8} data-color={`black-40-opacity`} data-lines={2}>
                                    {match?.occupation}
                                </p>
                            </div> 
                        </div> 
                        <div className={`row left nowrap x-auto w-flex-p-2 px-2 neg-mx-2`}>
                            {[
                                <FormattedMessage id="match_about"/>, 
                                <FormattedMessage id="match_requests"/>, 
                                <FormattedMessage id="match_projects"/>,
                            ].map((elem, index: number) => 
                            <button key={index} className={`block btn-tab-new mobile`} 
                                    data-color={index === matchTab ? `black-40-opacity`: `light-gray`}
                                    onClick={() => { setMatchTab(index) }}>
                                <p>{elem}</p>
                            </button>)}
                        </div>   
                    </div> 
                    <div className={`w-flex h-4 neg-mb-4 noshrink`} style={{
                        background: `linear-gradient(rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0))`,
                        position: `relative`, zIndex: 2
                    }}></div>                      
                    <div className={`w-flex-p-3 neg-mx-3 y-auto p-3 h-9`} id={`match-scroll-block`}> 
                        {matchTab === 0 && <>                    
                        {match?.industry?.length > 0 &&
                        <div className={`row w-flex nowrap mb-1`}>
                            <div className={`my row left w-flex`} style={{overflow: `hidden`, height: 19}}>
                                {match?.industry.map((elem: string, index: number) => {return (
                                    <p data-size={8} style={{color: CreateHSLColor(elem)}} 
                                    className={`semibold seminarrow`} key={index}>{elem}</p>
                                )})}
                            </div> 
                        </div>} 
                        <p data-size={8} data-lines={true} className={`mb-1`}>
                            {(match?.about ?? '').trim()
                            .split(`\n`).map((elem_: string, index: number) => {return (elem_ ?
                                <span className={`article`} key={index}>{elem_}</span> : null
                            )})}
                        </p>
                        <div className={`neg-mb-1`}></div>
                        </>} 
                        {matchTab === 1 &&  
                        OnlyValid(match?.requests).map((elem: any, index: number) => {return (
                        <div key={index} className={`block w-flex p-2 b-2 mb-1 clickable hover-dark-white`} onClick={() => { 
                            if (window.getSelection()?.type !== "Range") {
                                const newWindow: WindowProxy | null = window.open(`/request/${elem?.id}`, '_blank');
                                if (newWindow) {
                                    newWindow.focus();
                                }
                            }
                        }} data-border={`gray-1`}>
                            <div className={`neg-mx-1 px-1 w-flex-p-1`} data-lines={4}
                                    id={`feed-card-about-${elem?.id}`} data-scroll={isReadMore?.length > 0 && isReadMore[index] === false}>
                                {elem?.answers.reduce((init: string, elem: string) => {
                                    return `${init}${init && '\n'}${elem.trim()}`;
                                }, '').trim().split(`\n`).map((elem: string, index: number) => {return ( elem !== '' ?
                                <p data-size={6} className={`article`} data-color={'black'} key={index}>
                                    {elem}
                                </p> : null)})}
                            </div>

                            {isReadMore[index] &&
                            <button className={`btn`} onClick={e => {
                                        e.stopPropagation();
                                        setIsReadMore(val => SetDict(val, [index], false));
                                    }}>
                                <p data-size={6} className={`semibold hover mt`} data-color={'black'}>
                                    <FormattedMessage id="read_more"/>
                                </p>
                            </button>}
                        </div>)})} 
                        {matchTab === 2 && 
                        <div className={`w-flex-p-2 neg-mx-2`}>
                            <div className={`row nogap nowrap w-flex`}>        
                                <div className={`row x-auto left top noshrink nowrap gap-2 w-flex px-2`}>
                                    {context.profile?.projects.map((elem : any, index: number) => {return (
                                        <SiteCard project={elem} key={index}
                                                onUpdate={() => {
                                                        context.UpdateData();
                                                }} mobile={true} orderNumber={index} lower={true}/>
                                    )})}
                                </div> 
                            </div> 
                        </div>}
                    </div>                    
                    <div className={`w-flex h-4 neg-mt-4 noshrink`} style={{
                        background: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 70%)`,
                        position: `relative`, zIndex: 2
                    }}></div> 
                    {match?.is_met ?
                    <button className={`block btn-flex desktop noshrink`} data-color={`light-gray`} 
                            onClick={() => { setFeedBackPopup(match) }}>
                        <p><FormattedMessage id="match_feedback"/></p>
                    </button> :
                    <button className={`block btn-flex desktop noshrink`} data-color={`green`} onClick={() => {
                        context.setNewChatOffer({
                            created_at: Date.now() / 1000,    
                            items: [],
                            pair: match,
                            user: context.profile,
                            is_new_requested: true, 
                            is_chat_offer: true,
                        });
                        context.setPage(2, 0);
                        context.setTab(0, 2);
                    }}>
                        <p><FormattedMessage id="match_arrange"/></p>
                    </button>}
                </div>) : ((
                    context.profile?.is_ready ||
                    context.profile?.subscription?.first_flow
                ) && rateFlow ?
                <div className="block b-3 p-3 w-flex">
                    {rateFlow === "rate" && false ? <>
                    <p data-size={5} className={`semibold seminarrow mb-1`}>
                        <FormattedMessage id="match_rate_title"/>
                    </p>
                    <p data-size={7} className={`mb-3`}>
                        <FormattedMessage id="match_rate_description_1"/><br/> 
                        <FormattedMessage id="match_rate_description_2"/>
                    </p>
                    <div className={`block p-2 b-2 mb-3`} data-border={`gray-1`} style={{minHeight: 168}}>
                        <div className={`row top nowrap mb-1`}>
                            <div className={`row left nowrap`}>
                                <div className={`block b-1 noshrink`} data-color={`gray`} style={{
                                    width: 40, height: 40,
                                    backgroundImage: `url("${(toRateProfiles ?? [])[toRateRatedNum]?.user?.avatar?.link}")`,
                                }}></div>
                                <div>
                                    <p data-size={7} data-color={`black-40-opacity`} data-lines={1}>
                                        {(toRateProfiles ?? [])[toRateRatedNum]?.user?.occupation}
                                    </p>
                                    <div className={`row left w-flex h-3 neg-mb`} style={{overflow: `hidden`}}>
                                        {(toRateProfiles ?? [])[toRateRatedNum]?.user?.industry.map((elem: string, index: number) => {return (
                                            <p data-size={7} style={{color: CreateHSLColor(elem)}} 
                                            className={`semibold seminarrow`} key={index}>{elem}</p>
                                        )})}
                                    </div>
                                </div>
                            </div>
                            <div className={`block b-3 h-3 min-w-4 centered noshrink`} data-color={`green`}>
                                <p data-size={7}>{`${toRateRatedNum + 1}/${Math.min(toRateProfiles?.length, 5)}`}</p>
                            </div>
                        </div>

                        <div className={`w-flex-p-1 neg-mx-1 neg-my p-1 py b-1`}>
                            <p data-size={8} data-lines={4} data-scroll={(toRateProfiles ?? [])[toRateRatedNum]?.user?.readMore === false} 
                               id={`rate-user-about-${(toRateProfiles ?? [])[toRateRatedNum]?.user?.id}`}>
                                {((toRateProfiles ?? [])[toRateRatedNum]?.user?.about ?? '').trim()
                                .split(`\n`).map((elem_: string, index: number) => {return (elem_ ?
                                    <span className={`article`} key={index}>{elem_}</span> : null
                                )})}
                            </p>
                            {(toRateProfiles ?? [])[toRateRatedNum]?.user?.readMore &&                             
                            <button className={`btn mobile`} onClick={e => {
                                e.stopPropagation();
                                setToRateProfiles((val: any) => SetDict(
                                    val, [toRateRatedNum, "user", "readMore"], 
                                    false,
                                ))
                            }}>
                                <p data-size={8} className={`semibold seminarrow pt`}>
                                    <FormattedMessage id="read_more"/>
                                </p>
                            </button>}
                        </div>
                    </div>

                    <input type={`range`} className={`range-mobile w-flex mb-3 neg-mt-1`} min={0} max={100} 
                           value={(toRateProfiles ?? [])[toRateRatedNum]?.user?.rating ?? 0} 
                           data-full={(toRateProfiles ?? [])[toRateRatedNum]?.user?.rating === 100}
                       onChange={e => { setToRateProfiles((val: any) => SetDict(val, [toRateRatedNum, "user", "rating"], Number(e.target.value))) }}
                       style={{backgroundSize: `${(toRateProfiles ?? [])[toRateRatedNum]?.user?.rating ?? 0}% 100%`}}></input>
                    <div className={`row w-flex nowrap px-1 mb-4`}>
                        {[
                            <FormattedMessage id="match_rate_not_relevant"/>, 
                            <FormattedMessage id="match_rate_relevant"/>
                        ].map((elem, index: number) => 
                        <p data-size={8} key={index}>{elem}</p>
                        )}
                    </div>
                    
                    <div className={`grid-1-1 w-flex`} style={{gap: 8}}>                        
                        {context.isSubscriptionActive &&
                        <button className={`block btn-flex mobile row center`} data-color={`light-gray`} 
                                onClick={() => { 
                                    ToRateSkip();
                                    setRateFlow(undefined);
                                    setToRateProfiles(null);
                                }}>
                            <p><FormattedMessage id="skip"/></p>
                        </button>}
                        <button className={`block btn-flex mobile row center`} data-color={`black`} 
                                onClick={() => { 
                                    if (toRateRatedNum + 1 >= Math.min(toRateProfiles?.length, 5)) {
                                        (toRateProfiles ?? []).forEach((elem: any) => {
                                            RelevanceRequest(
                                                elem?.user?.id, 
                                                [String(elem?.user?.rating)], 
                                                elem?.user?.rating >= 50,
                                            )
                                        })
                                        setRateFlow(undefined);
                                        setToRateProfiles(null);
                                        context.setProfile((val: any) => SetDict(val, ["subscription", "first_flow_finished"], true));
                                    } else {
                                        setToRateRatedNum(val => Math.min(val + 1, Math.min(toRateProfiles?.length, 5) - 1));
                                    } 
                                }}
                                disabled={(toRateProfiles ?? [])[toRateRatedNum]?.user?.rating === undefined}>
                            <p><FormattedMessage id="next"/></p>
                        </button>
                    </div>
                    </> : <>
                    <div className={`row nowrap w-flex mb-1`}>
                        <p data-size={5} className={`semibold seminarrow`}>
                            <FormattedMessage id="match_select_title"/>
                        </p>  
                        <div className={`block b-3 h-3 min-w-4 centered noshrink`} data-color={`green`}>
                            <p data-size={6}>{`${toRateRatedNum + 1}/${Math.floor(toRateProfiles?.length / 2)}`}</p>
                        </div> 
                    </div>               
                    <p data-size={7} className={`mb-3`}>
                        <FormattedMessage id="match_select_description_1"/><br/> 
                        <FormattedMessage id="match_select_description_2"/>
                    </p>
                    <div className="grid-1 w-flex mb-4" style={{gap: 14}}>
                        {(toRateProfiles ?? []).slice(toRateRatedNum * 2, toRateRatedNum * 2 + 2).map((elem: any, index: number) => 
                        <div className={`block p-2 b-2 w-flex h-flex clickable`} data-color={`light-gray`}
                             onClick={() => { setToRateSelectedNum(index) }} style={index === toRateSelectedNum ? {
                                boxShadow: `0 0 0 4px rgb(255, 255, 255), 0 0 0 6px rgba(0, 0, 0, 0.4)`,
                                padding: 8, margin: 6, width: `calc(100% - 12px)`, height: `calc(100% - 12px)`,
                             } : {}}>
                            <div className={`row left nowrap mb-1`}>
                                <div className={`block b-1 noshrink`} data-color={`gray`} style={{
                                    width: 40, height: 40,
                                    backgroundImage: `url("${elem?.user?.avatar?.link}")`,
                                }}></div>
                                <div>
                                    <p data-size={7} data-color={`black-40-opacity`} data-lines={1}>
                                        {elem?.user?.occupation}
                                    </p>
                                    <div className={`row left w-flex h-3 neg-mb`} style={{overflow: `hidden`}}>
                                        {elem?.user?.industry.map((elem: string, index: number) => {return (
                                            <p data-size={7} style={{color: CreateHSLColor(elem)}} 
                                            className={`semibold seminarrow`} key={index}>{elem}</p>
                                        )})}
                                    </div>
                                </div>
                            </div>

                            <div className={`w-flex-p neg-m p b-1`}>
                                <p data-size={8} data-lines={4} data-scroll={elem?.user?.readMore === false} 
                                   id={`rate-user-about-${elem?.user?.id}`} style={{maxHeight: 200}}>
                                    {(elem?.user?.about ?? '').trim()
                                    .split(`\n`).map((elem_: string, index: number) => {return (elem_ ?
                                        <span className={`article`} key={index}>{elem_}</span> : null
                                    )})}
                                </p>
                                {elem?.user?.readMore && 
                                <button className={`btn mobile`} onClick={e => {
                                    e.stopPropagation();
                                    setToRateProfiles((val: any) => SetDict(
                                        val, [index, "user", "readMore"], 
                                        false,
                                    ))
                                }}>
                                    <p data-size={8} className={`semibold seminarrow pt`}>
                                        <FormattedMessage id="read_more"/>
                                    </p>
                                </button>}
                            </div>
                        </div>)}
                    </div>

                    <div className={`grid-1-1 w-flex`} style={{gap: 8}}>                        
                        {context.isSubscriptionActive &&
                        <button className={`block btn-flex desktop row center`} data-color={`light-gray`} 
                                onClick={() => { 
                                    ToRateSkip();
                                    setRateFlow(undefined);
                                    setToRateProfiles(null);
                                }}>
                            <p><FormattedMessage id="skip"/></p>
                        </button>}
                        <button className={`block btn-flex desktop row center`} data-color={`black`} 
                                onClick={() => { 
                                    RelevanceRequest(
                                        toRateProfiles[toRateRatedNum * 2 + 1 - (toRateSelectedNum ?? 0)]?.user?.id, 
                                        [`${toRateProfiles[toRateRatedNum * 2 + 1 - (toRateSelectedNum ?? 0)]?.user?.id} worse than ${toRateProfiles[toRateRatedNum * 2 + (toRateSelectedNum ?? 0)]?.user?.id}`], 
                                        false,
                                    )
                                    if (toRateRatedNum * 2 + 4 > toRateProfiles?.length) {
                                        setRateFlow(undefined);
                                        setToRateProfiles(null);
                                        context.setProfile((val: any) => SetDict(val, ["subscription", "first_flow_finished"], true));
                                    } else {
                                        setToRateRatedNum(val => val + 1);
                                        setToRateSelectedNum(undefined);
                                    }
                                }}
                                disabled={toRateSelectedNum === undefined}>
                            <p><FormattedMessage id="next"/></p>
                        </button>
                    </div>
                    </>}
                </div> : (context.profile?.subscription?.is_subscription_active === false && 
                <BecomeAMemberBlock/>))}

                {context.profile?.subscription?.is_subscription_active &&
                <div className={`block b-3 w-flex h-9 column p-2`}>
                    {context.profile?.is_ready ? <>
                    <div></div>
                    <div className={`column top`}>
                        <CardMatchesVideoMobile className={`mb-3`}/>
                        <p data-size={6} className={`center mb-3`} data-color={`black-40-opacity`}>
                            <FormattedMessage id="match_next_in"/>&nbsp;{UntilMatch()?.text}{"."}<br/>
                            <FormattedMessage id="match_notify"/>
                        </p>
                        <button className={`block btn-flex mobile row center`} data-color={`light-gray`}
                                style={{width: 191}} onClick={() => {
                                    setWaitReadyStatus(true);
                                    SetReadyStatus(false, {
                                        0: () => { setWaitReadyStatus(false) },
                                        200: () => { 
                                            context.setProfile((val: any) => SetDict(val, ["is_ready"], false));
                                        },
                                    })
                                }}>
                            <p><FormattedMessage id="match_pause"/></p>
                            {waitReadyStatus &&
                            <Loading width={24} height={24} color={`black`}/>}
                        </button>
                        <button className={`block btn-flex mobile row center mt-1`} data-color={CommunityLogo[globalContext.community]?.custom_color ?? `black`}
                                style={{width: 191}} onClick={() => {
                                    context.setPage(1, 1);
                                    context.setTab(1, 1);
                                }}>
                            <p><FormattedMessage id="go_to_feed"/></p>
                        </button>
                    </div>
                    <p data-size={7} className={`center`} data-color={`black-40-opacity`}>
                        <FormattedMessage id="match_email_hint_1"/>{" "}
                        <FormattedMessage id="match_email_hint_2"/>
                        <span data-color={`blue`}>
                            {`noreply@osmos.social`}
                        </span>
                        <FormattedMessage id="match_email_hint_3"/>
                    </p>
                    </> : <>
                    <div></div>
                    <div className={`column top`}>
                        <p data-size={4} className={`semibold seminarrow max-w-9 center mb-1`}>
                            <FormattedMessage id="match_are_you_ready"/>
                        </p>
                        <p data-size={8} data-color={`black-40-opacity`}>
                            <FormattedMessage id="match_until"/>
                        </p>
                        <button className={`block btn-flex mobile row center mt-3`} data-color={`black`}
                                style={{width: 191}} onClick={() => {
                                    setWaitReadyStatus(true);
                                    SetReadyStatus(true, {
                                        0: () => { setWaitReadyStatus(false) },
                                        200: () => { 
                                            context.setProfile((val: any) => SetDict(val, ["is_ready"], true));
                                        },
                                    })
                                }}>
                            <p><FormattedMessage id="match_start"/></p>
                            {waitReadyStatus &&
                            <Loading width={24} height={24} color={`white`}/>}
                        </button>
                    </div>
                    <div></div>
                    </>}
                </div>}
                </> :
                <div className={`w-flex b-3 h-9 skeleton`}></div>}
            </div>

            {!!feedBackPopup &&
            <GradePopup show={!!feedBackPopup} match={feedBackPopup} onLeaveFeedback={() => { setMatch(null) }}
                setShow={(show: boolean) => { setFeedBackPopup((val: any) => show ? val : undefined) }}/>}
        </div>
    )
}

export const ProfilePageMobile = (props: any) => {
    const [ cookie ] = useCookies([`user_id_cookie`]);
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();
    const context = useContext(ProfileContext);
    const globalContext = useContext(GlobalContext);

    const [ email, setEmail ] = useState<string>("");    
    const [ waitEmail, setWaitEmail ] = useState<boolean>(false);
    const [ mailOkPopup, setMailOkPopup ] = useState<boolean>(false);
    const [ mailErrorPopup, setMailErrorPopup ] = useState<boolean>(false); 

    const [ subscriptions, setSubscriptions ] = useState<{
        followers: any[],
        follow: any[],
    }>();

    const [ rndColor, setRndColor ] = useState<string[]>([]);
    const [ waitStripe, setWaitStripe ] = useState<boolean>(false);

    const [ tab, setTab ] = useState<number>(searchParams.get(`target`) ? (props.other ? 1 : 0) : 0);

    useEffect(() => {
        setRndColor(ScreenColors[Math.floor(Math.random() * 3)]);
    }, [context.profile?.projects ?? null])

    useEffect(() => {
        if (context.profile?.id) {
            SubscriptionsRequest(
                context.profile?.id,
                setSubscriptions,
            )
        }
    }, [context.profile?.id])

    return(<>
        <div className={`w-flex px-3`}>
            {(tab === 0 || props.other) && (props.loaded ? <>
                {!props.other && (!context.isSubscriptionActive ? 
                <div className={`block b-3 p-2 w-flex mb-3`} data-color={`coral`}>
                    <p data-size={8} className={`seminarrow semibold mb center`}>
                        {`Your subscription ${context.profile?.subscription?.is_stripe_customer ? "has expired" : "is not active"}`}
                    </p>
                    <p data-size={6} className={`mb-2 center`}>
                        <FormattedMessage id="hope_back"/>
                    </p>

                    <button className={`block btn-rectangle-40 mobile w-flex row center`} onClick={() => { 
                        setWaitStripe(true);
                        OpenStripePortal(
                            context?.profile?.subscription?.is_stripe_customer, 
                            () => { setWaitStripe(false) },
                            undefined, 
                            context.profile?.subscription?.is_subscription_active ? '2m' : undefined
                        ) 
                    }}>
                        {waitStripe ? <Loading width={24} height={24} color={`coral`}/> :
                        <p data-color={`coral`}><FormattedMessage id="activate_subscription"/></p>}
                    </button>
                </div> : (context.profile?.subscription?.is_subscription_paused && Until(context.profile?.subscription?.expire_at)?.days < 8 &&
                <div className={`block b-3 p-2 w-flex mb-3`} data-color={`coral`}>
                    <p data-size={8} className={`seminarrow semibold mb center`}>
                        {`Your subscription expires in ${
                            Until(context.profile?.subscription?.expire_at)?.days === 7 ? `one week` : 
                            `${Until(context.profile?.subscription?.expire_at)?.days} day${Until(context.profile?.subscription?.expire_at)?.days > 1 ? "s" : ""}`
                        }`}
                    </p>
                    <p data-size={6} className={`mb-2 center`}>
                        <FormattedMessage id="hope_back"/>
                    </p>

                    <button className={`block btn-rectangle-40 mobile w-flex row center`} onClick={() => { 
                        setWaitStripe(true);
                        OpenStripePortal(context?.profile?.subscription?.is_stripe_customer, () => { setWaitStripe(false) }) 
                    }}>
                        {waitStripe ? <Loading width={24} height={24} color={`coral`}/> :
                        <p data-color={`coral`}><FormattedMessage id="activate_subscription"/></p>}
                    </button>
                </div>
                ))}

                <div className={`block b-3 p-3 desktop w-flex my-2`} hidden={context.profile?.hideUser}>
                    <NewAvatar size={0} name={context.profile?.name} avatar={context.profile?.avatar?.link}
                                type={context.profile?.avatar?.style} className={`circled mb-2`}/>
                    <p data-size={4} className={`mb semibold`}>{context.profile?.name}</p>
                    <p data-size={5}>
                        {context.profile?.occupation}
                    </p>                    
                    <div className={`row left nowrap nogap mt-1`} hidden={!context.profile?.location?.city}>
                        <img src={LocationGray} alt={``} className={`w-3`}></img>
                        <p data-size={5} data-color={`black-40-opacity`} className={`w-flex ellipsis`}>
                            {context.profile?.location?.city}
                        </p>
                    </div>
                    <div className={`row nowrap mt-2`} hidden={!cookie[`user_id_cookie`] || cookie[`user_id_cookie`] === context.profile?.id}>
                        {context.profile?.is_matched ?
                        <a className={`block btn-circled-40 mobile row center w-flex`} data-color={`green`}
                            href={`/profile/chat?chat_id=${context.profile?.id}`} target={`_blank`}>
                            <p><FormattedMessage id="match_arrange"/></p>
                        </a> :
                        <button className={`block btn-circled-40 mobile row center w-flex`} data-color={`green`}
                                onClick={() => {
                                    // context.setWishToHelpRequest1({user_id: context.profile?.id});
                                    // context.setWishToHelpPopup1(true);
                                    sessionStorage.setItem("new_chat_offer", JSON.stringify({
                                        created_at: Date.now() / 1000,    
                                        items: [],
                                        pair: context.profile,
                                        user: context.ownerProfile,
                                        is_new_requested: true, 
                                        is_chat_offer: true,
                                    }));
                                    navigate(`/profile/chat?new_chat_offer=true`);
                                }} hidden={true} disabled={!context.isSubscriptionActive}>
                            <p><FormattedMessage id="match_arrange"/></p>
                        </button>}
                    </div>
                </div>
                
                {!props.other && <>
                <button className={`block btn-block-new mobile w-flex row mb-1 mt-2`} onClick={() => { context.setTab(3) }}
                        style={{padding: 14, borderRadius: 14}}>
                    <p><FormattedMessage id="profile_edit"/></p>
                    <Edit color={`black`} className={`w-3 h-3`}/>
                </button>
                <button className={`block btn-block-new mobile w-flex row nowrap mb-3`} onClick={() => {
                    // if (document.querySelector(`#open-settings-button-main`)) {
                    //     (document.querySelector(`#open-settings-button-main`) as HTMLButtonElement).click();
                    // }
                    context.setTab(4);
                }} style={{padding: 14, borderRadius: 14}}>
                    <p><FormattedMessage id="profile_settings"/></p>
                    <img src={GearBlack} alt={``} className={`h-3`}></img>
                </button></>}

                {context.profile?.links && context.isSubscriptionActive &&
                <div className={`block p-2 b-3 w-flex row grid-1 gap-1 mt-3`}>
                    {context.profile?.links?.email &&
                    <button className={`w-flex row top nowrap`}
                        onClick={() => {
                            const newWindow: WindowProxy | null = window.open(`mailto:${context.profile?.links?.email}`, '_blank');
                            if (newWindow) {
                                newWindow.focus();
                            }
                        }}>
                        <div className={`block w-3 h-3 b centered noshrink`} data-color={`black`}>
                            <p data-size={5} className={`semibold narrow`} style={{marginTop: `-0.2em`}}>
                                {`@`}
                            </p>
                        </div>
                        <div className="row nogap" style={{width: `calc(100% - 28px)`}}>
                            <p className={`ellipsis semibold seminarrow`} data-size={8} style={{lineHeight: `24px`}}>
                                {context.profile?.links?.email.split('@')[0]}
                                {`@${context.profile?.links?.email.split('@')[1]}`}
                            </p>
                            {/* <p data-size={8} style={{lineHeight: `24px`}}>{`@${context.profile?.links?.email.split('@')[1]}`}</p> */}
                        </div>
                    </button>}
                    {[
                        {title: 'LinkedIn', logo: LinkedInWhite, link: true},
                        {title: 'WhatsApp', logo: PhoneWhite},
                        {title: 'iMessage', logo: AppleWhite},
                        {title: 'Facebook', logo: FacebookWhite, link: true},
                        {title: 'Telegram', logo: TelegramWhite, link: 'https://t.me/'},
                        {title: 'X', logo: XWhite, link: true},
                    ].map((elem, index) => {return (
                        context.profile?.links[elem.title.toLowerCase()] ?
                        <div className={`w-flex row top nowrap`} key={index}> 
                            <div className={`block w-3 h-3 b centered noshrink`} data-color={`black`}>                           
                                <img src={elem.logo} alt={`ph`} style={{width: 20}}></img>
                            </div>
                            {elem.link ? 
                            <a data-size={8} className={`ellipsis`} style={{wordBreak: 'break-word', lineHeight: `24px`, width: `calc(100% - 32px)`}} 
                            target={`_blank`}
                            href={MakeValidURL(`${typeof elem.link === `string` ? elem.link : ''}${context.profile?.links[elem.title.toLowerCase()].replace(`@`, '')}`)}>
                                <span data-color={`black`} className={`semibold seminarrow`}>
                                    {context.profile?.links[elem.title.toLowerCase()]}
                                </span>
                            </a> :
                            <p data-size={8} className={`ellipsis semibold seminarrow`} style={{wordBreak: 'break-word', lineHeight: `24px`, width: `calc(100% - 32px)`}}>
                                {context.profile?.links[elem.title.toLowerCase()]}
                            </p>}
                        </div> : null
                    )})}
                </div>}

                {!cookie[`user_id_cookie`] &&                    
                <div className={`block w-flex p-3 b-3 mb-2`}>
                    <p data-size={4} className={`center semibold seminarrow mb-3`}>
                        <FormattedMessage id="sign_in"/>
                    </p>
                    {
                        [
                            {title: `Google`, logo: GoogleLogo, onClick: () => {
                                GoogleAuth(
                                    null, 
                                    null, 
                                    encodeURIComponent(`/profile/${context.profile?.id}`), 
                                    null,
                                    {
                                        203: () => {
                                            navigate('/notfound');
                                        }
                                    },
                                )
                            }, hidden: false},
                            {title: `LinkedIn`, logo: LinkedInLogo, onClick: () => {
                                LinkedInAuth(
                                    null, 
                                    null, 
                                    encodeURIComponent(`/profile/${context.profile?.id}`), 
                                    null,
                                    {
                                        203: () => {
                                            navigate('/notfound');
                                        }
                                    },
                                )
                            }, hidden: true},
                        ].map((elem, index) => {return (
                            <button className={`btn-google mobile w-flex row center mb-1`}
                                    data-color={`black`} onClick={() => {
                                        elem.onClick();
                                    }} key={index} hidden={elem.hidden}>
                                <img src={elem.logo} alt={`li`}></img>
                                <p><FormattedMessage id="reg_continue_with"/>&nbsp;{elem.title}</p>
                            </button>
                        )})
                    }                
                    <div className={`w-flex row my-2 nowrap`}>
                        <div className={`block w-flex`} data-color={`black-10-opacity`} style={{height: 1}}></div>
                        <p data-size={8} data-color={`black-40-opacity`} className={`noshrink semibold seminarrow`}>
                            <FormattedMessage id="or"/>
                        </p>
                        <div className={`block w-flex`} data-color={`black-10-opacity`} style={{height: 1}}></div>
                    </div>
                    <form className={`w-flex`} onSubmit={e => {
                        e.preventDefault();
                        if (email) {
                            setWaitEmail(true); 
                            setTimeout(() => { setWaitEmail(false) }, 5000);
                            EmailAuth(
                                email, () => {}, () => {}, 
                                null, 
                                null, 
                                encodeURIComponent(`/profile/${context.profile?.id}`),
                                null,
                                {
                                    200: () => {                                     
                                        setWaitEmail(false);
                                        setMailOkPopup(true);
                                    },
                                    0: () => {
                                        setMailErrorPopup(true);
                                    },
                                    203: () => {
                                        navigate('/notfound');
                                    }
                                }, 
                            )
                        } 
                    }}>
                        <div className={`row mobile input-block mb-1`}>
                            <input className={`mobile`} placeholder={`Email address`} type={`email`}
                                    value={email} onChange={e => {
                                        setEmail(e.target.value);
                                    }} required={true} data-size={6}></input> 
                                <p className={`text-4 semibold`} style={{marginTop: '-0.1em'}}>{`@`}</p>
                        </div>
                            
                        <button className={`block btn-flex mobile w-flex row center`}
                                data-color={`black`} type={`submit`}>
                            {waitEmail ? 
                            <Loading color={`white`} width={24} height={24}/> : 
                            <p><FormattedMessage id="reg_continue"/></p>}
                        </button>
                    </form>
                    <div className={`mt-3 row center nogap nowrap`}>
                        <p data-size={8} data-color={`black-40-opacity`} className={`semibold seminarrow`}>
                            <FormattedMessage id="reg_new_in"/>&nbsp;	
                            <a href={`/`}>
                                <span data-color={`black`}><FormattedMessage id="reg_join_now"/></span>
                            </a>
                        </p>
                    </div>
                </div>}
            </> : <>
                <div className={`skeleton b-3 w-flex h-8 mb-3`}></div>
                <div className={`skeleton b-2 w-flex h-5 mb-1`}></div>
                <div className={`skeleton b-2 w-flex h-5 mb-3`}></div>
            </>)}

            {props.loaded ? <>
                <div className={`mb-3`}></div>
                <div className={`row left x-auto nowrap noshrink mobile px-3 my-3 w-flex-p-3 neg-mx-3`} hidden={!cookie[`user_id_cookie`] || true}>
                    <button className={`block btn-tab-new mobile row`} data-color={tab === 0 ? `black` : `white`}
                            onClick={() => { setTab(0) }}>
                        <p><FormattedMessage id="match_about"/></p>
                    </button>                    
                    <button className={`block btn-tab-new mobile row`} data-color={tab === 2 ? `black` : `white`}
                            onClick={() => { setTab(2) }} hidden={props.other && !subscriptions?.followers?.length} disabled={!subscriptions?.followers?.length}>
                        <p><FormattedMessage id="followers"/></p>
                        {!!subscriptions?.followers?.length &&
                        <div className={`block min-w-3 h-3 b-2 centered neg-mr`} data-color={`blue`}>
                            <p data-size={6} className={`not-active`}>{subscriptions?.followers?.length}</p>
                        </div>}
                    </button>
                    <button className={`block btn-tab-new mobile row`} data-color={tab === 3 ? `black` : `white`}
                            onClick={() => { setTab(3) }} hidden={props.other && !subscriptions?.follow?.length} disabled={!subscriptions?.follow?.length}>
                        <p><FormattedMessage id="following"/></p>
                        {!!subscriptions?.follow?.length &&
                        <div className={`block min-w-3 h-3 b-2 centered neg-mr`} data-color={`blue`}>
                            <p data-size={6} className={`not-active`}>{subscriptions?.follow?.length}</p>
                        </div>}
                    </button>
                </div>

                {tab === 0 && <>
                {(context.profile?.industry?.length > 0 || !!context.profile?.about) &&
                <div className={`block b-3 p-2 w-flex mb-2`}> 
                    {context.profile?.industry?.length > 0 &&
                    <div className={`my row left w-flex h-3 mb-1`} style={{overflow: `hidden`}}>
                        {context.profile?.industry.map((elem: string, index: number) => {return (
                            <p data-size={8} style={{color: CreateHSLColor(elem)}} 
                                className={`semibold seminarrow`} key={index}>{elem}</p>
                        )})}
                    </div>}
                    <p data-size={6} data-lines={true} className={`mb-1`}>
                        {context.profile?.about.trim()
                        .split(`\n`).map((elem_: string, index: number) => {return (elem_ ?
                            <span className={`article`} key={index}>{elem_}</span> : null
                        )})}
                    </p>
                    <div className={`neg-mb-1`}></div>
                </div>}

                {!!CommunityLogo[globalContext.community]?.custom_blocks?.profile_after_about && 
                CommunityLogo[globalContext.community]?.custom_blocks?.profile_after_about?.type === 'image' ? 
                <img src={CommunityLogo[globalContext.community]?.custom_blocks?.profile_after_about?.image_url} alt=""
                    className={`w-flex b-3 clickable mb-2`} onClick={() => {
                        if (CommunityLogo[globalContext.community]?.custom_blocks?.profile_after_about?.redirect_url) {
                            navigate(CommunityLogo[globalContext.community]?.custom_blocks?.profile_after_about?.redirect_url);
                        }
                    }}></img>
                : null}

                {!!OnlyValid(context.profile?.requests)?.length &&
                <div className={`block p-2 b-3 w-flex mb-2`} style={{paddingBottom: 6}}>
                    <div className={`row w-flex nowrap mb-2 px-1 pt-1`}>
                        <div className={`row left nowrap`}>
                            <div className={`block min-w-3 h-3 centered b-2`} data-color={rndColor[2]}>
                                <p data-size={6} className={`px-1`}>{OnlyValid(context.profile?.requests)?.length}</p>
                            </div>
                            <p className={`semibold seminarrow`}><FormattedMessage id="profile_requests"/></p>
                        </div>
                        {!props.other &&
                        <button className={`btn-symbol w-3 h-3 centered`} data-color={rndColor[2]}
                                onClick={() => { context.setAddRequestPopup(true) }}>
                            <img src={rndColor[2] === 'white' ? PlusWhite : PlusBlack} alt={`+`}></img>
                        </button>}
                    </div>
                    {OnlyValid(context.profile?.requests).map((elem: any, index: number) => {return (
                        <ProfileRequestCardMobile request={elem} className={`mb-1`} key={index}/>
                    )})}
                </div>}

                {!!context.profile?.projects?.length && 
                <div className={`block p-2 b-3 w-flex mb-2`} id={`profile-projects-block`}>
                    <div className={`row nowrap pt-1 px-1`}>
                        <div className={`row left nowrap`}>
                            <div className={`block min-w-3 h-3 centered b-2`} data-color={rndColor[2]}>
                                <p data-size={6} className={`px-1`}>{context.profile?.projects?.length}</p>
                            </div>
                            <p className={`semibold seminarrow`}><FormattedMessage id="profile_projects"/></p>
                        </div>
                        {!props.other &&
                        <button className={`btn-symbol w-3 h-3 centered`} data-color={rndColor[2]}
                                onClick={() => { context.setAddProjectPopup(true) }}>
                            <img src={rndColor[2] === 'white' ? PlusWhite : PlusBlack} alt={`+`}></img>
                        </button>}
                    </div>
                    <div className={`row nogap nowrap min-w-flex neg-mx-2 mt-2`}>
                        <div className={`row x-auto left top noshrink nowrap nogap w-flex px-2`}>
                            {context.profile?.projects.map((elem : any, index: number) => {return (
                                <SiteCard project={elem} key={index} className={`${!!index ? `ml-2` : ``}`} 
                                          isEditable={!props.other} onUpdate={() => {
                                                context.UpdateData();
                                          }} mobile={true} orderNumber={index}/>
                            )})}
                        </div> 
                    </div> 
                </div>}    

                {!props.other && cookie[`user_id_cookie`] === context.profile?.id && 
                (!context.profile?.about || context.profile?.projects?.length === 0) &&
                <div className={`block b-3 mobile w-flex mb-2 column p-3`} 
                        data-color={`extra-light-coral`} data-border={`coral-3`} style={{
                            justifyContent: `center`, 
                            minHeight: 229,
                        }}>
                    <p data-size={4} data-color={`coral`} className={`semibold seminarrow center`} style={{maxWidth: 418}}>
                        {(context.profile?.requests?.length === 0) ? 
                        <>
                            <FormattedMessage id="profile_add_request_hint"/>&nbsp;
                            {CommunityLogo[globalContext.community]?.title ?? "Osmos"}
                        </> :
                        (!context.profile?.about ? <FormattedMessage id="profile_tell_about"/> : (
                        context.profile?.projects?.length === 0 && <FormattedMessage id="profile_add_project"/>))}
                    </p>
                    {!!context.profile?.about && context.profile?.projects?.length === 0 && context.profile?.requests?.length !== 0 &&
                    <p data-size={8} className={`mt-1 center max-w-8`} data-color={`coral`}>
                        <FormattedMessage id="profile_add_project_description"/>
                    </p>}
                    <button className={`block btn-flex mobile mt-3 row center`} onClick={() => {
                            if (context.profile?.requests?.length === 0) {
                                context.setAddRequestPopup(true);
                            } else if (!context.profile?.about) {
                                context.setPage(0, 3);
                                context.setTab(3, 0);
                            } else {
                                context.setAddProjectPopup(true);
                            }
                        }} data-color={`coral`} style={{minWidth: 169}}>
                        <p>
                            {(context.profile?.requests?.length === 0) ? <FormattedMessage id="profile_add_request"/> : (
                            !context.profile?.about ? <FormattedMessage id="profile_add_about"/> : (
                            context.profile?.projects?.length === 0 && <FormattedMessage id="profile_add_project"/>))}
                        </p>
                    </button>
                </div>}             

                {context.isSubscriptionActive && props.other &&
                <div className={`block p-2 b-3 w-flex mb-3`}>
                    <div className={`row left top nowrap px-1 pt-1`}>
                        <div className={`block min-w-3 h-3 b-3 centered noshrink`} data-color={`black`}>
                            {!!context.recommendationsCommon?.length ? 
                            <p data-size={6} className={`narrow px-1`}>{context.recommendationsCommon?.length}</p> :
                            <Loading color={`white`} width={14} height={14}/>}
                        </div>
                        <p className={`semibold seminarrow`} style={{paddingTop: 2}}>
                            {cookie[`user_id_cookie`] === context.profile?.id ? <FormattedMessage id="feed_similar"/> : `People similar to ${context.profile?.name}`}
                        </p>
                    </div>
                    <div className={`row nogap nowrap min-w-flex neg-mx-2 mt-2`}>
                        <div className={`row x-auto left top noshrink nowrap nogap w-flex px-2`}>
                            {context.recommendationsCommon?.length ?
                            context.recommendationsCommon.map((elem: any, index: number) => {return (
                                <SmallUserBlock profile={elem?.user} index={index} className={`${!!index ? `ml-2` : ``}`} 
                                                setRecommendations={context.setRecommendationsCommon} mobile={true} key={index}
                                                onClick={() => { 
                                                    // context.setProfilePopup(elem?.user);
                                                    const newWindow: WindowProxy | null = window.open(`/profile/${elem?.user?.id}`, '_blank');
                                                    if (newWindow) {
                                                        newWindow.focus();
                                                    } 
                                                }}/>
                            )}) :
                            [1, 2, 3].map((elem, index) => {return (
                                <div className={`block b-3 p-2 column top ${!!index ? `ml-2` : ``}`} key={index}
                                     data-border={`gray-1`}>
                                    <NewAvatar size={0} className={`mb-2 circled`}/>
                                    <div style={{height: 61}}>
                                        <div className={`skeleton b-4 h-3 mb-1`} style={{width: 180}}></div>
                                        <div className={`skeleton b-3 h-2 mb-2`} style={{width: 195}}></div>
                                    </div>
                                    <div className={`skeleton b-4`} style={{width: 198, height: 40}}></div>
                                </div>
                            )})}
                        </div> 
                    </div> 
                </div>}                
                </>}                

                {tab === 2 && !!subscriptions?.followers?.length && 
                <div className={`block p-3 b-3 w-flex mb-3 y-auto`} style={{maxHeight: 471, paddingBottom: 16}}>
                    {subscriptions?.followers.map((elem: any, index: number) => {return (
                        <a href={`/profile/${elem.unique_id ?? elem.id}`} className={`block w-flex-p-1 p-1 b-2 neg-mx-1 ${!!index ? `mt-1` : 'neg-mt-1'} row nowrap left`}
                           onTouchStart={e => { (e.target as HTMLButtonElement).setAttribute(`data-color`, `light-gray`) }}
                           onTouchEnd={e => { (e.target as HTMLButtonElement).removeAttribute(`data-color`) }}
                           onTouchMove={e => { (e.target as HTMLButtonElement).removeAttribute(`data-color`) }} key={index}>
                            <NewAvatar name={elem.name} avatar={elem.avatar?.link} type={elem.avatar?.style} size={4}/>
                            <div>
                                <p data-size={5} className={`semibold seminarrow`} data-lines={1}>{elem.name}</p>
                                <p data-size={6} data-color={`black-40-opacity`} data-lines={1}>{elem.occupation}</p>
                            </div>
                        </a>
                    )})}
                </div>}

                {tab === 3 && !!subscriptions?.follow?.length &&
                <div className={`block p-3 b-3 w-flex mb-3 y-auto`} style={{maxHeight: 471, paddingBottom: 16}}>
                    {subscriptions?.follow.map((elem: any, index: number) => {return (
                        <a href={`/profile/${elem.unique_id ?? elem.id}`} className={`block w-flex-p-1 p-1 b-2 neg-mx-1 ${!!index ? `mt-1` : 'neg-mt-1'} row nowrap left`}
                           onTouchStart={e => { (e.target as HTMLButtonElement).setAttribute(`data-color`, `light-gray`) }}
                           onTouchEnd={e => { (e.target as HTMLButtonElement).removeAttribute(`data-color`) }}
                           onTouchMove={e => { (e.target as HTMLButtonElement).removeAttribute(`data-color`) }} key={index}>
                            <NewAvatar name={elem.name} avatar={elem.avatar?.link} type={elem.avatar?.style} size={4}/>
                            <div>
                                <p data-size={5} className={`semibold seminarrow`} data-lines={1}>{elem.name}</p>
                                <p data-size={6} data-color={`black-40-opacity`} data-lines={1}>{elem.occupation}</p>
                            </div>
                        </a>
                    )})}
                </div>}
            </> : <>
                <div className={`skeleton w-flex b-3 h-8 mb-3`}></div>
                <div className={`skeleton w-flex b-3 h-8 mb-3`}></div>
            </>} 

            {mailOkPopup &&
            <InfoPopup _id={`mail-ok-popup`} show={mailOkPopup} setShow={setMailOkPopup}
                    title={<FormattedMessage id="reg_check_email"/>}
                    buttonTitle={<FormattedMessage id="reg_ok_got_it"/>} onOk={() => {}} mobile={true}/>}

            {mailErrorPopup &&
            <InfoErrorPopup show={mailErrorPopup} setShow={setMailErrorPopup}
                            title={<FormattedMessage id="reg_error"/>}
                            buttonTitle={<FormattedMessage id="reg_reload_page"/>} onOk={() => {
                                    setMailErrorPopup(false);
                                    window.location.reload();
                            }} mobile={true}/>}  
        </div>        
    </>)
}

export const ProfileInvitesMobile = (props: any) => {
    const [ cookie ] = useCookies([`user_id_cookie`]);

    const [ tab, setTab ] = useState<number>(0);
    const [ hintStep, setHintStep ] = useState<number>(0);

    const [ inviteMessage, setInviteMessage ] = useState<string>('Hey, i\'ve been using Osmos for a while and thought you would like it as well!');
    const [ inviteeEmail, setInviteeEmail ] = useState<string>('');
    const [ contacts, setContacts ] = useState<any[]>();
    const [ contactsLoaded, setContactsLoaded ] = useState<boolean>(false);
    const [ searchText, setSearchText ] = useState<string>(``);

    const [ newInvite, setNewInvite ] = useState<any>();
    const [ newInviteMail, setNewInviteMail ] = useState<any>();
    const [ invitePopup, setInvitePopup ] = useState<boolean>(false);

    const [ showCopied, setShowCopied ] = useState<boolean>(false); 
    const [ rndColor, setRndColor ] = useState<string[]>([]);

    const FilterList = (list: any[], search: string = '') => {
        return list.filter(val => (
            (val.user?.fname ?? '').toLowerCase().startsWith(search.toLowerCase()) ||
            (val.user?.lname ?? '').toLowerCase().startsWith(search.toLowerCase()) ||
            (val.user?.name ?? '').toLowerCase().startsWith(search.toLowerCase()) ||
            (val.user?.email ?? '').toLowerCase().startsWith(search.toLowerCase())
        ))
    }
    useEffect(() => {
        (newInvite ?? []).forEach((elem: any) => {
            if (elem?.token) {
                if (window.isSecureContext) {
                    try {
                        Share(`${window.location.origin}?code=${elem?.token}`, () => {
                            setShowCopied(true);
                            setTimeout(() => {
                                setShowCopied(false);
                            }, 2000)
                        })
                    } catch (error) {
                        console.warn("Fail to share link:", error)
                        ClipBoard(`${window.location.origin}?code=${elem?.token}`, () => {
                            setShowCopied(true);
                            setTimeout(() => {
                                setShowCopied(false);
                            }, 2000)
                        })
                    }
                }
    
                props.setProfile((val: any) => SetDict(val, ['invites', `pending`], [elem].concat(val.invites.pending)));
                // props.setProfile((val: any) => SetDict(val, ['invites', `available`], val.invites.available - 1));
            }
        })
    }, [newInvite]);

    useEffect(() => {
        (contacts ?? [{
            user: {
                email: inviteeEmail,
                SendInvite: EmailRegExp.test(inviteeEmail),
            }
        }]).filter(val => val?.user?.sendInvite).forEach((elem: any, index: number) => {
            if ((newInviteMail ?? [])[index]?.token) {
                if (elem?.user?.email && inviteMessage) {
                    SendInvite(
                        elem?.user?.email,
                        inviteMessage,
                        `${window.location.origin}?code=${newInviteMail[index]?.token}`,
                        () => {  }
                    )
                }

                props.setProfile((val: any) => SetDict(val, ['invites', `pending`], [newInviteMail[index]].concat(val.invites.pending)));
                // props.setProfile((val: any) => SetDict(val, ['invites', `available`], val.invites.available - 1));
            }
        })
    }, [newInviteMail]);

    useEffect(() => {
        setRndColor(ScreenColors[Math.floor(Math.random() * 3)]);
        ContactsRequest(cookie[`user_id_cookie`], setContacts, false, () => {
            setContactsLoaded(true);
        }, () => {
            setContactsLoaded(true);
        });
    }, [])

    useEffect(() => {
        if (props.profile?.invites) {
            if (!props.profile?.invites?.accepted?.length) {
                // console.warn(`no accepted`)
                setTab(1);
            } else if (!(props.profile?.invites?.pending ?? []).filter((val: any) => !val.hidden).length) {
                // console.warn(`no pending`)
                setTab(0);
            }
        }
    }, [props.profile])

    return (<>
        <div className={`p-3 pt-2`}>
            {!!props.profile?.first_login && ((props.profile?.first_login ?? [`invites`]).includes('invites') ? <>
            <div className={`block b-3 p-3 mb-2`}>                
                {newInvite ? <>
                <input className={`mobile ellipsis mb-1`} readOnly={true} type={`text`} required={true}
                    value={`${window.location.origin}?code=${(newInvite ?? [])[0]?.token ?? `...`}`} data-size={6}></input> 
                <button className={`block btn-circled-40 mobile w-flex row center`} data-color={`light-gray`} onClick={() => {
                    if (window.isSecureContext) {
                        navigator.share({
                            title: "Osmos",
                            url: `${window.location.origin}?code=${(newInvite ?? [])[0]?.token}`,
                        }).catch(error => {                    
                            navigator.clipboard.writeText(`${window.location.origin}?code=${(newInvite ?? [])[0]?.token}`)
                            .then(() => {;
                                setShowCopied(true);
                                setTimeout(() => {
                                    setShowCopied(false);
                                }, 2000)
                                setNewInvite(undefined);
                            })
                        })
                        .then(() => {                            
                            setNewInvite(undefined);
                        })
                    }
                }}>
                    <p><FormattedMessage id="share_invite"/></p>
                </button></> : 
                <button className={`block btn-circled-40 mobile w-flex row center`} data-color={`light-gray`} 
                        onClick={() => { InviteTokenUse(setNewInvite) }}>
                    <p><FormattedMessage id="gen_invite_link"/></p>
                </button>}
            </div>

            {contactsLoaded ?
            (!contacts?.length ?
            <div className={`block b-3 p-3 mb-2`} data-color={rndColor[0]} hidden={false}>             
                <form className={`w-flex`} onSubmit={e => {
                    e.preventDefault();
                    if (inviteMessage && EmailRegExp.test(inviteeEmail)) {
                        InviteTokenUse(setNewInviteMail, null, 1, {
                            200: () => {
                                (e.target as Element).classList.remove(`invalid-form`);
                            }
                        })
                    }
                }} id={`invite-mail-form`}>
                    <div className={`row mb-2`}>
                        <p data-size={5} className={`semibold seminarrow`}><FormattedMessage id="invite_contacts"/></p>
                        {!contacts &&
                        <button className={`block btn-circled-32 mobile`} data-color={`black`} type={`button`}
                                onClick={() => { ContactsRequest(cookie[`user_id_cookie`], setContacts) }}>
                            <p><FormattedMessage id="browse_contacts"/></p>
                        </button>}
                    </div>

                    <input className={`mobile mb-1 ${!EmailRegExp.test(inviteeEmail) ? 'in' : ''}valid`} type={`email`} required={true} placeholder={`Enter email`}
                            value={inviteeEmail} onChange={e => {setInviteeEmail(e.target.value)}} data-size={6}></input> 
                    <p className={`after neg-mt-1 mb-1 pt`} data-color={`coral`}></p>

                    <textarea className={`mobile neg-mb b-1 h-6 p-2 ${!inviteMessage ? 'in' : ''}valid`} required={true}
                            value={inviteMessage} onChange={e => {setInviteMessage(e.target.value)}} data-size={6}></textarea> 
                    <p className={`after pt`} data-color={`coral`}></p>

                    <button className={`block btn-circled-40 mobile mt-2`} data-color={`black`} type={`submit`} onClick={() => {
                        const mailForm = document.querySelector(`#invite-mail-form`);
                        if (mailForm) {
                            mailForm.classList.add(`invalid-form`);
                        }
                    }} disabled={!inviteeEmail || !inviteMessage}>
                        <p className={`semibold`} data-color={`white`}><FormattedMessage id="invite_send"/></p>
                    </button>
                </form>
            </div> :
            <div className={`block label btn-block mobile w-flex`} data-color={rndColor[0]} hidden={false}>
                <div className={`row nowrap mb-2`}>
                    <p data-size={5} className={`semibold seminarrow`}><FormattedMessage id="invite_contacts"/></p>
                    <button className={`block btn-circled-32 mobile`} data-color={`black`}
                            data-bg-color={rndColor[0]}
                            onClick={() => { 
                                setInvitePopup(true);  
                            }} disabled={contacts.filter(val => val?.user?.sendInvite)?.length === 0}>
                        <p><FormattedMessage id="continue"/></p>
                    </button>
                </div>
                <input className={`block mobile mb`} type={`email`} required={true} placeholder={`Search by name or email`}
                       value={searchText} onChange={e => {setSearchText(e.target.value)}} data-size={6} data-color={`white`}></input> 

                <div className={`block p-1 b-2`}>
                    {!!contacts?.length ?
                    FilterList(contacts, searchText).map((elem: any, index: number, array: any[]) => {                        
                    const prev = !array[index - 1]?.user?.sendInvite !== !elem?.user?.sendInvite || !index;
                    const next = !array[index + 1]?.user?.sendInvite !== !elem?.user?.sendInvite || index + 1 === array?.length;
                    return (<Fragment key={index}>
                        <div className={`block w-flex`} data-color={`gray`} 
                            style={{height: 1, marginTop: -1}} hidden={!index || prev}></div>
                        <button className={`block btn mobile row nowrap w-flex p-1 ${(prev && next) ? `b-1` : (prev ? `bt-1` : (next ? `bb-1` : ``))}`} key={index}
                                    onClick={() => { setContacts(
                                        val => SetDict(val, [index, "user", "sendInvite"], !elem?.user?.sendInvite)
                                    ) }} data-color={elem?.user?.sendInvite ? `light-gray` : null}>
                            <div className={`row nowrap left`} style={{maxWidth: `calc(100% - 32px)`}}>
                                <NewAvatar size={3} avatar={elem?.user?.avatar?.link} type={0} className={`not-active`}/>
                                <p data-size={6} className={`semibold seminarrow not-active ellipsis`}>{elem?.user?.email}</p>
                            </div>
                            <div className={`checkbox-radio w-3 h-3 b-2 centered noshrink`} data-checked={elem?.user?.sendInvite}>
                                <div className={`checkbox-center h-1 w-1 b`}></div>
                            </div>
                        </button>
                    </Fragment>)}) :
                    [1, 2, 3].map((elem, index) => {return (
                        <div className={`block b-3 p-2 column top ${!!index ? `ml-2` : ``}`} key={index}>
                            <NewAvatar size={0} className={`mb-2 circled`}/>
                            <div className={`h-5`}>
                                <div className={`skeleton b-4 h-3 mb-1`} style={{width: 180}}></div>
                                <div className={`skeleton b-3 h-2 mb-1`} style={{width: 195}}></div>
                            </div>
                            <div className={`skeleton b-4`} style={{width: 198, height: 40}}></div>
                        </div>
                    )})}
                </div>
            </div>) :
            <div className={`w-flex h-7 b-3 skeleton`}></div>}

            <div className={`row w-flex-p-3 left nowrap px-3 my-3 neg-mx-3`} hidden={
                !props.profile?.invites?.accepted?.length && 
                !(props.profile?.invites?.pending ?? []).filter((val: any) => !val.hidden).length
            }>
                {!!props.profile ? <>
                    <button className={`block btn-tab-new mobile row`} data-color={tab === 0 ? `black` : `white`}
                            onClick={() => { setTab(0) }} disabled={!props.profile?.invites?.accepted?.length}>
                        <p><FormattedMessage id="accepted"/></p>
                        {!!props.profile?.invites?.accepted?.length && 
                        <div className={`block min-w-3 h-3 b-3 centered`} data-color={`${tab === 0 ? 'white' : `black`}-40-opacity`}>
                            <p data-size={6} className={`narrow`} data-color={`white`}>{props.profile?.invites?.accepted?.length}</p>
                        </div>}
                    </button>
                    <button className={`block btn-tab-new mobile row`} data-color={tab === 1 ? `black` : `white`}
                            onClick={() => { setTab(1) }} disabled={!(props.profile?.invites?.pending ?? []).filter((val: any) => !val.hidden).length}>
                        <p><FormattedMessage id="pending"/></p>
                        {!!(props.profile?.invites?.pending ?? []).filter((val: any) => !val.hidden).length && 
                        <div className={`block min-w-3 h-3 b-3 centered`} data-color={`${tab === 1 ? 'white' : `black`}-40-opacity`}>
                            <p data-size={6} className={`narrow`} data-color={`white`}>{(props.profile?.invites?.pending ?? []).filter((val: any) => !val.hidden).length}</p>
                        </div>}
                    </button>
                </> : <>
                    <div className={`label btn-tab-new mobile skeleton`}>
                        <p><FormattedMessage id="accepted"/></p>
                    </div>
                    <div className={`label btn-tab-new mobile skeleton`}>
                        <p><FormattedMessage id="pending"/></p>
                    </div>
                </>}
            </div>
            
            {tab === 0 &&
            <div className={`block b-3 p-3 ${!!props.profile?.invites?.accepted?.length ? `py` : ``}`} hidden={!props.profile?.invites?.accepted?.length}>
                {/* {!props.profile?.invites?.accepted?.length && <>
                    <p data-size={5} className={`semibold mb-1`} data-color={`black`}><FormattedMessage id="requests_accepted"/></p>
                    <p data-size={6} data-color={`black-10-opacity`} className={`semibold`}><FormattedMessage id="add_friends_to_feed"/></p>
                </>} */}
                {(props.profile?.invites?.accepted ?? []).map((elem: any, index: number) => {return (
                    <div className={`block b-2 p-2 w-flex mt-2`} data-color={`light-gray`} key={index}>
                        <div className={`row top nowrap mb-2`}>
                            <p data-size={6} data-color={`black`}>
                                {DateTimeFromString(elem?.time ?? Date.now() / 1000)}&nbsp;	
                                <span data-color={`blue`}>{elem?.name ?? `User`}</span>
                                {` joined Osmos.`}
                            </p>
                            <NewAvatar name={elem?.name ?? `. .`} type={elem?.avatar?.style ?? 0} 
                                    avatar={elem?.avatar?.link} size={4}/>
                        </div>
                        <div className={`row left`}>
                            <button className={`block btn-circled-40 mobile centered`}
                                    onClick={() => {
                                        Subscribe(cookie['user_id_cookie'], elem?.id);
                                    }} disabled={elem?.followed}>
                                <p><FormattedMessage id="add_to_feed"/></p>
                            </button>
                        </div>
                    </div>
                )})}
            </div>}

            {tab === 1 &&
            <div className={`block b-3 p-3 ${!!(props.profile?.invites?.pending ?? []).filter((val: any) => !val.hidden).length ? `py` : ``}`} style={{overflow: `hidden`}}
                 hidden={!(props.profile?.invites?.pending ?? []).filter((val: any) => !val.hidden).length}>
                {/* {!(props.profile?.invites?.pending ?? []).filter((val: any) => !val.hidden).length && <>
                    <p data-size={5} className={`semibold mb-1`} data-color={`black`}><FormattedMessage id="requests_pending"/></p>
                    <p data-size={6} data-color={`black-10-opacity`} className={`semibold`}><FormattedMessage id="control_invites"/></p>
                </>} */}
                {(props.profile?.invites?.pending ?? []).map((elem: any, index: number) => {return (elem.hidden ? null :
                <div className={`b-3 pt-3 px w-flex`} key={index}
                    style={{
                        transition: `margin 0.3s ease-in-out`,
                    }} id={`pending-link-block-${elem?.token}`}>
                    <p data-size={6} className={`mb-1`} data-color={`black-40-opacity`}>{DateTimeFromString(elem?.time ?? Date.now() / 1000)}</p>
                    <button className={`block row left nogap nowrap w-flex mb-1 mobile btn-flex`} onClick={() => {
                        if (window.isSecureContext) {                  
                            navigator.clipboard.writeText(`${window.location.origin}?code=${elem?.token}`)
                            .then(() => {;
                                setShowCopied(true);
                                setTimeout(() => {
                                    setShowCopied(false);
                                }, 2000)
                            })
                        }
                    }} data-color={`light-gray`}>
                        <p data-size={6} className={`w-flex pr-4 regular ellipsis`} data-color={`black`}>{`${window.location.origin}?code=${elem?.token}`}</p>
                        <img className={`w-3 neg-ml-3 hover-icon`} src={LinkBlack} alt={""}></img>
                    </button>
                    <button className={`block btn-circled-32 mobile`} data-color={`light-gray`}
                            onClick={() => {
                                InviteTokenBurn(elem?.token, () => {
                                    const pending_invite_elem = document.querySelector(`#pending-link-block-${elem?.token}`);
                                    if (pending_invite_elem) {
                                        (pending_invite_elem as HTMLDivElement).style.marginLeft = `800px`;
                                        setTimeout(() => {
                                            (pending_invite_elem as HTMLDivElement).style.marginTop = `-${pending_invite_elem.scrollHeight}px`;
                                            setTimeout(() => {
                                                props.setProfile((val: any) => SetDict(val, [`invites`, `pending`, val.invites.pending.findIndex((val: any) => val.token === elem?.token), 'hidden'], true));
                                            }, 300)
                                        }, 300) 
                                    }
                                })
                            }}>
                        <p><FormattedMessage id="revoke_link"/></p>
                    </button>
                    <div className={`block w-flex-p neg-mx mt-3`} data-color={`gray`} style={{height: 1}}></div>
                </div>)})}
                <div className={`block w-flex-p neg-mx mt-3`} style={{height: 1, marginTop: -1}}></div>
            </div>} 
            </> : 
            <div className={`px-3`}>
                <p data-size={3} className={`semibold mb`} data-color={`green`}><FormattedMessage id="invite_reasons_1"/></p> 
                <p data-size={4} className={`semibold seminarrow mb-3`} data-color={`black`}><FormattedMessage id="invite_reasons_2"/></p>

                <div id={`invites-hint-1`} style={{
                    transition: `opacity 0.2s ease-in-out`,
                    marginBottom: -32,
                }}>
                    <div className={`block w-3 h-3 b-2 centered mb-1`} data-color={`green`}>
                        <p data-size={6}>{`1`}</p>
                    </div>
                    <p data-size={5} data-color={`black`} className={`mb-2`}><FormattedMessage id="invite_reason_1"/></p>
                    
                    <button className={`block btn-circled-40 mobile`} data-color={`black`} onClick={() => { 
                        setHintStep(1);
                        const eventsHint_1 = document.querySelector(`#invites-hint-1`);
                        const eventsHint_2 = document.querySelector(`#invites-hint-2`);
                        if (eventsHint_1 && eventsHint_2) {
                            (eventsHint_1 as HTMLDivElement).style.opacity = `0.4`;
                            (eventsHint_2 as HTMLDivElement).style.opacity = `1`;
                            (eventsHint_2 as HTMLDivElement).style.paddingTop = `0px`;
                        }
                    }} style={{
                        transition: `opacity 0.2s ease-in-out`,
                        opacity: !(hintStep) ? 1 : 0,
                    }}>
                        <p><FormattedMessage id="invite_reason_btn_1"/></p>
                    </button>
                </div>

                <div id={`invites-hint-2`} style={{
                    transition: `opacity 1s ease-in-out, padding 0.6s ease-in-out`,
                    marginBottom: -32,
                    opacity: 0,
                    pointerEvents: `none`,
                    paddingTop: 20,
                }}>
                    <div className={`block w-3 h-3 b-2 centered mb-1`} data-color={`green`}>
                        <p data-size={6}>{`2`}</p>
                    </div>
                    <p data-size={5} data-color={`black`} className={`mb-2`}><FormattedMessage id="invite_reason_2"/></p>
                    <button className={`block btn-circled-40 mobile`} data-color={`black`} onClick={() => { 
                        setHintStep(2);  
                        const eventsHint_2 = document.querySelector(`#invites-hint-2`);
                        const eventsHint_3 = document.querySelector(`#invites-hint-3`);
                        if (eventsHint_2 && eventsHint_3) {                          
                            (eventsHint_2 as HTMLDivElement).style.transitionDuration = `0.2s`;
                            (eventsHint_2 as HTMLDivElement).style.opacity = `0.4`;
                            (eventsHint_3 as HTMLDivElement).style.opacity = `1`;
                            (eventsHint_3 as HTMLDivElement).style.paddingTop = `0px`;
                        }
                    }}style={{
                        opacity: !(hintStep - 1) ? 1 : 0,
                        transition: `opacity 0.2s ease-in-out`,
                    }}>
                        <p><FormattedMessage id="invite_reason_btn_2"/></p>
                    </button>
                </div>

                <div id={`invites-hint-3`} style={{
                    transition: `opacity 1s ease-in-out, padding 0.6s ease-in-out`,
                    opacity: 0,
                    pointerEvents: `none`,
                    paddingTop: 20,
                }}>
                    <div className={`block w-3 h-3 b-2 centered mb-1`} data-color={`green`}>
                        <p data-size={6}>{`3`}</p>
                    </div>
                    <p data-size={5} data-color={`black`} className={`mb-2`}>
                        {`Offer help, make intros, give — and you will receive much more in return.`}
                    </p>
                    <button className={`block btn-circled-40 mobile`} data-color={`black`} onClick={() => { 
                        props.setProfile((val: any) => SetDict(val, [`first_login`], val.first_login.concat(`invites`)));
                        FirstLoginAdd(props.profile?.id, 'invites');
                    }}style={{
                        opacity: !(hintStep - 2) ? 1 : 0,
                        transition: `opacity 0.2s ease-in-out`,
                    }}>
                        <p><FormattedMessage id="invite_reason_btn_3"/></p>
                    </button>
                </div>
            </div>)}
        </div>

        {invitePopup &&
        <PopupInviteMobile show={invitePopup} setShow={setInvitePopup} setInvitees={setContacts}
                           invitees={contacts} onFailure={() => {}} message={inviteMessage} setMessage={setInviteMessage}
                           setNewInvite={setNewInviteMail}/>}

        <div className={`block p-3 b-3 row top nogap nowrap`} data-color={`black`} style={{
            position: 'fixed',
            zIndex: 20,
            bottom: showCopied ? 30 : -200,
            right: 30,
            width: `calc(100% - 60px)`,
            transition: `bottom 0.3s ease-in-out`,
        }} id={`successfully-copied-label`}>                
            <div className={`btn-symbol filled w-3 h-3 centered noshrink mr-2`} data-color={`green`}><img src={OkWhite} alt={`ok`}></img></div>
            <p data-size={5} className={`semibold w-flex`} data-color={`white`}>
                <FormattedMessage id="link_copied"/>
            </p>
        </div>  
    </>)
}

export const ProfileSettingsMobile = (props: any) => {
    const [ searchParams ] = useSearchParams();
    const context = useContext(ProfileContext);
    const globalContext = useContext(GlobalContext);
    
    const [ settingsPage, setSettingsPage ] = useState<number>(0);
    const [ popupBottom, setPopupBottom ] = useState<number | null>(null);
    const [ popupUnsubscribeAll, setPopupUnsubscribeAll ] = useState<number>(0);
    const [ popupLogOut, setPopupLogOut ] = useState<boolean>(false);
    const [ confirmEmailPopup, setConfirmEmailPopup ] = useState<boolean>(false);
    const [ waitPaymentPortal, setWaitPaymentPortal ] = useState<boolean>(false);

    const [ emailPrefs, setEmailPrefs ] = useState<any>();
    
    useEffect(() => {
        setEmailPrefs({
            projectUpdates: {
                enabled: !!props?.profile?.emails_project_news,
                title: <FormattedMessage id="settings_profile_email_pref_2"/>,
            },
            platformUpdates: {
                enabled: !!props?.profile?.emails_platform_updates,
                title: <FormattedMessage id="settings_profile_email_pref_3"/>,
            },
            calendarUpdates: {
                enabled: !!props?.profile?.emails_matches_calendar_reminders,
                title: <FormattedMessage id="ob_notify_matches"/>,
            },
            allUpdates: {
                enabled: true,
                title: ``,
            },
        })
    }, [props.profile])    

    useEffect(() => {
        if (searchParams.get(`target`) === `support`) {
            setSettingsPage(1);
        }
    }, [])

    return ( <>
        <div className={`w-flex px-3 pt-2`}>
            {(settingsPage === 0) &&
            <div>
                <button className={`btn-circled-24 mobile p-left row left nogap mb-5`} onClick={() => { context.setTab(0) }}>
                    <img src={AngleRightBlack} alt={`>`} style={{transform: `rotate(180deg)`}} className={`h-3`}></img>
                    <p data-size={5}><FormattedMessage id="settings"/></p>
                </button>

                <button className={`block btn-block-new mobile w-flex row mb-1`} onClick={() => { setSettingsPage(1) }}>
                    <p><FormattedMessage id="settings_support"/></p>
                    <img src={SupportBlack} className={`h-3`} alt={``}></img>
                </button>

                <button className={`block btn-block-new mobile w-flex row mb-1`} onClick={() => { setPopupBottom(1) }}>
                    <p><FormattedMessage id="privacy"/></p>
                    <img src={ProtectBlack} className={`h-3`} alt={``}></img>
                </button>

                <button className={`block btn-block-new mobile w-flex row mb-2`} onClick={() => { setPopupBottom(3) }}>
                    <p><FormattedMessage id="user_agreement"/></p>
                    <img src={AgreementBlack} className={`h-3`} alt={``}></img>
                </button>

                <button className={`block btn-block-new mobile w-flex row mb-1`} onClick={() => { setSettingsPage(2) }}>
                    <p><FormattedMessage id="settings_profile_settings"/></p>
                    <img src={SettingsBlack} className={`h-3`} alt={``}></img>
                </button>

                <button className={`block btn-block-new mobile w-flex row`} onClick={() => { setPopupLogOut(true) }}>
                    <p><FormattedMessage id="settings_logout"/></p>
                    <img src={ExitBlack} className={`h-3`} alt={``}></img>
                </button>
            </div>}
            
            {(settingsPage === 1) &&
            <div>
                <button className={`btn-circled-24 mobile p-left row nogap left mb-5`} onClick={() => { setSettingsPage(0) }}>
                    <img src={AngleRightBlack} alt={`>`} style={{transform: `rotate(180deg)`}} className={`h-3`}></img>
                    <p data-size={5}><FormattedMessage id="settings_support"/></p>
                </button>

                <div className={`block p-2 b-3 w-flex`}>
                    <p data-size={4} className={`seminarrow semibold mb-2`}><FormattedMessage id="settings_support_email"/></p>
                    
                    <a className={`block btn-flex mobile row w-flex`} data-color={'light-gray'} onClick={() => {
                                navigator.clipboard.writeText(`support@osmos.social`).then(() => {
                                    (document.querySelector(`#successfully-copied-label-settings`) as HTMLDivElement).style.bottom = `58px`;
                                    setTimeout(() => {
                                        (document.querySelector(`#successfully-copied-label-settings`) as HTMLDivElement).style.bottom = `-150px`;
                                    }, 2000)
                                });
                            }} href={"mailto:support@osmos.social"}>
                        <p className={`regular`} data-color={`black`}>{`support@osmos.social`}</p>
                        <img src={CopyBlack} alt={`copy`} title={`Copy link`}></img>
                    </a>
                </div>
            </div>}
            
            {(settingsPage === 2) &&
            <div>
                <button className={`btn-circled-24 mobile p-left row nogap left mb-5`} onClick={() => { setSettingsPage(0) }}>
                    <img src={AngleRightBlack} alt={`>`} style={{transform: `rotate(180deg)`}} className={`h-3`}></img>
                    <p data-size={5}><FormattedMessage id="settings_profile_settings"/></p>
                </button> 

                <div className={`block p-2 b-3 w-flex mb-2`}>
                    <p data-size={5} className={`seminarrow semibold mb-2 px`}><FormattedMessage id="settings_profile_email_prefs"/></p>

                    {[`calendarUpdates`, `projectUpdates`, `platformUpdates`].map((elem, index) => {return (
                        <button className={`w-flex mobile btn-checkbox row left top nowrap mb-1`} data-selected={emailPrefs[elem].enabled} key={index}
                                onClick={() => {
                                    setEmailPrefs((val: any) => SetDict(val, [elem, `enabled`], !val[elem].enabled));
                                }}>
                            <div className={`checkbox-radio h-3 w-3 b centered noshrink`} data-checked={emailPrefs[elem].enabled}>
                                <img src={OkWhite} alt={`v`}></img>
                            </div>
                            <p data-size={5}>{emailPrefs[elem].title}</p>
                        </button>
                    )})}

                    <button className={`block btn-circled-32 mobile row center w-flex mt-2 mb-1`} data-color={'black'} 
                            onClick={() => {
                                EmailPrefsUpdate(props?.profile?.id, {
                                    "emails_matches_calendar_reminders": emailPrefs[`calendarUpdates`].enabled,
                                    "emails_project_news": emailPrefs[`projectUpdates`].enabled,
                                    "emails_platform_updates": emailPrefs[`platformUpdates`].enabled,
                                }, () => {
                                    props.setProfile((val: any) => SetDict(val, ["emails_matches_calendar_reminders"], emailPrefs[`calendarUpdates`].enabled));
                                    props.setProfile((val: any) => SetDict(val, ["emails_project_news"], emailPrefs[`projectUpdates`].enabled));
                                    props.setProfile((val: any) => SetDict(val, ["emails_platform_updates"], emailPrefs[`platformUpdates`].enabled));
                                }, () => {}, () => {}, () => {}, () => {
                                    //confirmed
                                }, () => {
                                    setConfirmEmailPopup(true);
                                })
                            }}>
                        <p><FormattedMessage id="settings_profile_save"/></p>
                    </button>

                    <button className={`block btn-circled-32 mobile row center w-flex`} data-color={'transparent'} 
                            onClick={() => { setPopupUnsubscribeAll(1) }} style={{paddingLeft: 0, paddingRight: 0,}}>
                        <p data-color={`black`}><FormattedMessage id="settings_profile_email_unsub"/></p>
                    </button>
                </div> 

                {!globalContext?.community && (!context.profile?.subscription?.is_stripe_customer ?
                <button className={`block btn-block-new mobile w-flex row mb-1`} onClick={() => {
                    setWaitPaymentPortal(true);
                    OpenStripePortal(false, () => { setWaitPaymentPortal(false) });
                }} hidden={context.profile?.subscription?.is_subscription_active}>
                    <p><FormattedMessage id="activate_sub"/></p>
                    {waitPaymentPortal ? <Loading with={24} height={24}/> :
                    <img src={CardBlack} className={`h-3`} alt={``}></img>}
                </button> :
                <button className={`block btn-block-new mobile w-flex row top mb-1`} onClick={() => {
                    setWaitPaymentPortal(true);
                    OpenStripePortal(true, () => { setWaitPaymentPortal(false) });
                }}>
                    <div>
                        <p className={`mb-1`}>
                            {`My subscription`}
                        </p>
                        <p data-color={`black-40-opacity`}>
                            {`Expires ${DateFromString(context.profile?.subscription?.expire_at)}`}
                        </p>
                    </div>
                    {waitPaymentPortal ? <Loading width={24} height={24} color={`black`}/> :
                    <img src={CardBlack} className={`h-3`} alt={``}></img>}
                </button>)}

                <button className={`block btn-block-new mobile w-flex row`} onClick={() => { setPopupBottom(2) }}>
                    <p><FormattedMessage id="settings_profile_delete"/></p>
                    <img src={TrashBlack} className={`h-3`} alt={``}></img>
                </button>                  
            </div>}

            <div className={`block p-3 b-3 row top nogap nowrap`} data-color={`black`} style={{
                position: 'fixed', zIndex: 51,
                right: 30,
                bottom: -150,
                width: `calc(100% - 60px)`,
                transition: `bottom 0.3s ease-in-out`,
            }} id={`successfully-copied-label-settings`}>
                <div className={`block w-3 h-3 b-2 centered neg-mr-3`} data-color={`green`}>
                    <img src={OkWhite} alt={`v`} className={`h-2`}></img>
                </div>
                <p data-size={5} className={`semibold w-flex pl-4`} data-color={`white`}><FormattedMessage id="link_copied"/></p>
            </div>
        </div>

        {!!popupUnsubscribeAll && 
        <PopupUnsubscribeMobile show={!!popupUnsubscribeAll} setShow={(bool: boolean) => {
                                    setPopupUnsubscribeAll(val => bool ? val : 0);
                                }}
                                onUnsubscribeAll={() => {
                                    EmailPrefsUpdate(props?.profile?.id, {
                                        "emails_matches_calendar_reminders": false,
                                        "emails_project_news": false,
                                        "emails_platform_updates": false,
                                    }, () => {
                                        props.setProfile((val: any) => SetDict(val, ["emails_matches_calendar_reminders"], false));
                                        props.setProfile((val: any) => SetDict(val, ["emails_project_news"], false));
                                        props.setProfile((val: any) => SetDict(val, ["emails_platform_updates"], false));
                                        setPopupUnsubscribeAll(2);
                                    }, () => {}, () => {}, () => {}, () => {
                                        //confirmed
                                    }, () => {
                                        setConfirmEmailPopup(true);
                                    })
                                }} thanks={popupUnsubscribeAll === 2} profile={props.profile}/>}

        {!!popupLogOut && 
        <PopupLogOutMobile show={popupLogOut} setShow={setPopupLogOut} profile={props.profile}/>}

        {!!popupBottom &&
        <PopupBottomSettings settingsTab={popupBottom} setSettingsTab={setPopupBottom} profile={props.profile}/>}

        {confirmEmailPopup &&
        <PopupCenter show={confirmEmailPopup} setShow={setConfirmEmailPopup} mobile={true} onClose={() => {}}/>}
    </>)
}